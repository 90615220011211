import { useState } from 'react';

import { TrackedTableModal } from './TrackedTableModal';
import { WeekRow } from './WeekRow';

import { basicCategories, getBreakdownsByWeek, getTotalsByWeek, parseEvents, parseTrackedEvents } from './timeTrackingHelpers';
import { classNames } from '../../InvestigationBoard/helpers';

export function TimeTrackingMetricsTable({ events, qae }) {
  const [showTrackedDetails, setShowTrackedDetails] = useState(false);

  const { allDailyTotals, batchTotals } = parseEvents(events, qae?.qawId);
  const { allDailyBreakdowns, totalBreakdown } = parseTrackedEvents(events);

  // guarantee order of total breakdown
  const totalBreakdownMap = new Map(Object.entries(totalBreakdown));
  const totalTotal = totalBreakdownMap.get('Total');
  totalBreakdownMap.delete('Total');
  totalBreakdownMap.set('Total', totalTotal);
  const orderedTotalBreakdown = Object.fromEntries(totalBreakdownMap);

  // organize daily totals into week long segments
  const weeklyTotalsWithData = getTotalsByWeek(allDailyTotals);
  const weeklyBreakdownsWithData = getBreakdownsByWeek(allDailyBreakdowns);

  function handleShowTrackedDetails() {
    setShowTrackedDetails((prev) => !prev);
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full pt-2 align-middle">
            <table className="min-w-full border-spacing-0">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    Week #
                  </th>
                  {basicCategories.map((header, idx) => (
                    <th
                      key={idx}
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      {header}
                    </th>
                  ))}
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {Array.from(weeklyTotalsWithData).map(([week, weeklyTotals], weekIdx) => {
                  return (
                    <WeekRow
                      key={weekIdx}
                      week={week}
                      weeklyTotals={weeklyTotals}
                      dailyBreakdownsForWeek={weeklyBreakdownsWithData[week]}
                      isLastPart={weekIdx === Object.keys(weeklyTotalsWithData).length - 1}
                    />
                  );
                })}
                <tr
                  className={classNames(
                    'cursor-pointer bg-white rounded-b-md sticky bottom-0 z-1 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8',
                  )}
                  onClick={handleShowTrackedDetails}
                >
                  <td className="border-t border-gray-200 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                    Totals:
                  </td>
                  {Object.entries(batchTotals).map(([type, hours]) => {
                    return (
                      <td className="border-t border-gray-200 whitespace-nowrap px-3 py-4 text-sm text-gray-500" key={type}>
                        {hours}
                      </td>
                    );
                  })}
                  <td className="border-t border-gray-200 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"></td>
                </tr>
              </tbody>
              {showTrackedDetails && (
                <TrackedTableModal title={`Total Breakdown`} breakdown={orderedTotalBreakdown} handleShowTrackedDetails={handleShowTrackedDetails} />
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
