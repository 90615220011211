import { useMemo } from 'react';
import { useTimeTrackingMetrics } from '../helpers';
import WolfLoader from '../../WolfLoader/WolfLoader';
import { OrgTimeTrackingCard } from '../timeTracking/OrgTimeTrackingCard';
import { TeamTimeTrackingCard } from '../timeTracking/TeamTimeTrackingCard';

export default function TeamTimeTrackingView({ filters }) {
  // memo-ize teams from local storage
  const allTeams = useMemo(() => {
    return JSON.parse(localStorage.getItem('teams')).filter((team) => team.teamName !== 'TestTeam');
  }, []);
  const queries = useTimeTrackingMetrics(allTeams, filters);

  const { loadedTeams, isLoading, isError, errors } = useMemo(() => {
    // filter out the queries that haven't succeeded
    const successfulQueries = queries.filter((query) => query.isSuccess);

    // map the successful queries as just their data, sort them by team name
    const sortedLoadedTeams = successfulQueries.map((query) => query.data).sort((a, b) => a.qaTeam.name.localeCompare(b.qaTeam.name));

    return {
      loadedTeams: sortedLoadedTeams,
      isLoading: queries.some((query) => query.isLoading),
      isError: queries.some((query) => query.isError),
      errors: queries.filter((query) => query.isError).map((query) => query.error),
    };
  }, [queries]);

  // memo-ize the team to render based on the filters
  const teamToRender = useMemo(() => {
    // find the team in the loaded teams that matches the filters
    return loadedTeams.find((team) => team.qaTeam.name === filters.entity.data.name);
  }, [loadedTeams, filters.entity.data.name]);

  if (isLoading && loadedTeams.length <= 1) return <WolfLoader />;
  if (isError) return <ErrorDisplay errors={errors} />;

  return (
    <div className="min-h-screen px-4 flex-col rounded-lg shadow">
      <div className="flex flex-col min-h-full justify-around">
        {loadedTeams.length > 1 && (
          <div className="rounded-lg bg-gray-50 m-2 py-2 px-4 shadow">
            <OrgTimeTrackingCard teamsMetrics={loadedTeams} />
          </div>
        )}
        {isLoading && (
          <div className="w-full flex gap-x-6 justify-center items-center p-6">
            <WolfLoader customStyles={{ height: '50px', width: '50px' }} customText="" />
            <span>Loading more team data.{!teamToRender ? ' Your team will appear shortly...' : '..'}</span>
          </div>
        )}
        {teamToRender && (
          <div className="rounded-lg bg-gray-50 m-2 py-2 px-4 shadow overflow-hidden">
            <TeamTimeTrackingCard team={teamToRender} filters={filters} />
          </div>
        )}
      </div>
    </div>
  );
}

function ErrorDisplay({ errors }) {
  return (
    <div>
      <h2>
        <ul>
          {errors.map((error, index) => (
            <li key={index}>{error.message}</li>
          ))}
        </ul>
      </h2>
    </div>
  );
}
