import { InvestigationColumn } from './InvestigationColumn';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import { XCircleIcon, CheckCircleIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import { sendPostRequest } from '../../utils/network';
import Toast from '../Layout/Toast';
import { EnvNotesModal } from './EnvNotesModal';
import { NewTaskModal } from './NewTaskModal';
import { getOpenTasks } from './investigationActions';
import TeamSelector from '../Layout/TeamSelector';
import { useDisabledTasks } from '../../hooks/useDisabledTasks';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const taskTypeOrder = {
  message: 0,
  triage: 2,
  // bugVerification: 2,
  projectPlan: 3,
  none: 4,
  promotion: 1,
};

/**
 * Calculates triage statistics based on the provided parameters.
 * @param {Array} teamsToShow - The teams to show.
 * @param {import('../../types').QAWTask[]} allTriageTasks - The cards.
 * @param {import('../../types').QAWTask[]} toDoTriageTasks - The cards to show.
 * @param {import('../../types').QAWTask[]} inProgressTriageTasks - The cards to show.
 * @param {import('../../types').QAWTask[]} messageTasks - The message tasks.
 * @returns {{stats: Array, groupsSummary: object}} - The triage statistics.
 */
function getTriageStats(teamsToShow, allTriageTasks, toDoTriageTasks, inProgressTriageTasks, messageTasks) {
  let todoFailures =
    toDoTriageTasks
      .map((x) => x.suite?.runs?.fail)
      .reduce((x, y) => {
        return x + y;
      }, 0) || 0;

  let inProgressFailures = inProgressTriageTasks
    .map((x) => x.suite?.runs?.fail)
    .reduce((x, y) => {
      return x + y;
    }, 0);

  let stats = [
    { name: 'Unclaimed Suites:', stat: toDoTriageTasks.length },
    { name: 'Unclaimed Failures:', stat: todoFailures },
    { name: 'In Progress Suites:', stat: inProgressTriageTasks.length },
    { name: 'In Progress Failures:', stat: inProgressFailures },
  ];

  if (teamsToShow.some((x) => x.active === false)) {
    //  There are hidden teams, show hidden counters.
    let hiddenToDoTasks = allTriageTasks.filter((x) => x.status === 'toDo');
    let hiddenInProgressTasks = allTriageTasks.filter((x) => x.status === 'inProgress');

    let hiddenTodoFailures = hiddenToDoTasks
      .map((x) => x.suite?.runs?.fail)
      .reduce((x, y) => {
        return x + y;
      }, 0);

    let hiddenInProgressFailures = hiddenInProgressTasks
      .map((x) => x.suite?.runs?.fail)
      .reduce((x, y) => {
        return x + y;
      }, 0);

    stats = [
      {
        name: 'Unclaimed Suites',
        stat: `${toDoTriageTasks.length} (${hiddenToDoTasks.length - toDoTriageTasks.length} filtered)`,
      },
      {
        name: 'Unclaimed Failures',
        stat: `${todoFailures} (${hiddenTodoFailures - todoFailures} filtered)`,
      },
      {
        name: 'In Progress Suites',
        stat: `${inProgressTriageTasks.length} (${hiddenInProgressTasks.length - inProgressTriageTasks.length} filtered)`,
      },
      {
        name: 'In Progress Failures',
        stat: `${inProgressFailures} (${hiddenInProgressFailures - inProgressFailures} filtered)`,
      },
    ];
  }

  let simplifiedCards = allTriageTasks.map((x) => {
    return {
      id: x.id,
      ownerId: x.qaLead.email,
      numberOfFailures: (x.suite?.statusCounts?.fail - x.suite?.statusCounts?.bug),
    };
  });
  let groups = _.groupBy(simplifiedCards, 'ownerId');

  let groupsSummary = {};
  Object.keys(groups).forEach(function (key) {
    let group = groups[key];
    let suiteCount = group.length;
    let failureCount =
      group
        .map((g) => g.numberOfFailures)
        .reduce((x, y) => {
          return x + y;
        }, 0) || 0;

    groupsSummary[key] = { suiteCount, failureCount };
  });

  let messageGroups = _.groupBy(
    messageTasks.map((x) => {
      let lead = teamsToShow.find((y) => y.qawId === x.qaLead.id);
      return { ownerId: lead?.id || 'erice@qawolf.com' };
    }),
    'ownerId',
  );
  Object.keys(messageGroups).forEach(function (key) {
    if (!groupsSummary[key]) {
      groupsSummary[key] = { messages: 0 };
    }

    groupsSummary[key].messages = messageGroups[key].length;
  });

  return {
    stats,
    groupsSummary,
  };
}

export function InvestigationBoard() {
  const maxClaimedTriageTasks = 5;
  const filterSettingsJSON = localStorage.getItem('filterSettings');
  const filterSettings = filterSettingsJSON
    ? JSON.parse(filterSettingsJSON)
    : { showUserTasksOnly: false, showUserTodosOnly: false, showGenericTasks: true, showTriageTasks: true, showMessageTasks: true, showPromotionTasks: false, showPrimaryTeamOnly: false };

  const user = JSON.parse(localStorage.getItem('qawUsers')).find((x) => x.id === localStorage.getItem('userId'));

  const [showUserTasksOnly, setShowUserTasksOnly] = useState(filterSettings.showUserTasksOnly);
  const [showUserTodosOnly, setShowUserTodosOnly] = useState(filterSettings.showUserTodosOnly);
  const [showGenericTasks, setShowGenericTasks] = useState(filterSettings.showGenericTasks);
  const [showTriageTasks, setShowTriageTasks] = useState(filterSettings.showTriageTasks);
  const [showMessageTasks, setShowMessageTasks] = useState(filterSettings.showMessageTasks);
  const [showPromotionTasks, setShowPromotionTasks] = useState(filterSettings.showPromotionTasks);
  const [primaryTeamOnly, setPrimaryTeamOnly] = useState(filterSettings.showPrimaryTeamOnly);

  const [showSpinner, setShowSpinner] = useState(false);
  const [enabledTeams, setEnabledTeams] = useState([]);
  const [userShiftPrefs, setUserShiftPrefs] = useState({ suiteConfig: { teams: [], excludedClients: [] } });

  const [toast, setToast] = useState(<></>);

  const [showNotesModal, setShowNotesModal] = useState(false);
  const [notesModalData, setNotesModalData] = useState('');
  const [editingNotes, setEditingNotes] = useState(false);

  const [showNewTaskModal, setShowNewTaskModal] = useState(false);
  const [newTaskModalData, setNewTaskModalData] = useState({});

  const { setDisabledTasks } = useDisabledTasks();

  let triageStats = [];
  let teamTriageSummaries = {};

  /**
   * Takes in the array of all tasks and returns an object with an array of tasks for each columln.
   * @param {import('../../types').QAWTask[]} tasks
   * @returns {{ tasks: {
   *   toDoTasks: import('../../types').QAWTask[],
   *   inProgressTasks: import('../../types').QAWTask[],
   * }, hasUserClaimedMaxTasks: boolean }} The sorted tasks object.
   */
  const filterTasks = (tasks) => {
    let visibleLeadsIds = enabledTeams.length ? enabledTeams.filter((x) => x.active).map((y) => y.qawId) : ['ckyj0a6ar000x09mfefnp9irj']; // allows seeing Test Client when deselecting all teams

    // ensure tasks are sorted by type & filter out tasks for unselected teams
    let filteredTasks = tasks
      .sort((a, b) => {
        const aSubType = a.subType || a.type;
        const bSubType = b.subType || b.type;
        if (taskTypeOrder[aSubType] < taskTypeOrder[bSubType]) return -1;
        if (taskTypeOrder[aSubType] > taskTypeOrder[bSubType]) return 1;
        return 0;
      })
      .filter((x) => visibleLeadsIds.includes(x.qaLead.id));

    // Adjust tasks based on primaryTeamOnly state
    if (primaryTeamOnly) {
      filteredTasks = filteredTasks.filter(
        (task) =>
          !task.primaryQaTeam || task.qaLead.id === task.primaryQaTeam.lead.qawId,
      );
    }

    // partition tasks into toDo and inProgress
    const [todoTasks, inProgressTasks] = _.partition(filteredTasks, (x) => x.status === 'toDo');

    // generate triage stats
    let { stats, groupsSummary } = getTriageStats(
      enabledTeams,
      tasks.filter((x) => x.type === 'triage'),
      todoTasks.filter((x) => x.type === 'triage'),
      inProgressTasks.filter((x) => x.type === 'triage'),
      tasks.filter((x) => x.subType === 'message'), // message tasks
    );

    triageStats = stats;
    teamTriageSummaries = groupsSummary;

    // filter tasks based on selected view toggles
    const todoTasksFiltered = todoTasks.filter((x) => {
      if (x.type === 'triage') return showTriageTasks;
      if (x.type === 'promotion') return showPromotionTasks;
      if (x.subType === 'message') return showMessageTasks;
      if (x.subType === 'bugVerification') return showGenericTasks;
      if (x.subType === 'projectPlan') return showGenericTasks;
      if (x.subType === 'none') return showGenericTasks;
      return true;
    });

    // filter tasks based on selected view toggles
    const inProgressTasksFiltered = inProgressTasks.filter((x) => {
      if (x.type === 'triage') return showTriageTasks;
      if (x.type === 'promotion') return showPromotionTasks;
      if (x.subType === 'message') return showMessageTasks;
      if (x.subType === 'bugVerification') return showGenericTasks;
      if (x.subType === 'projectPlan') return showGenericTasks;
      if (x.subType === 'none') return showGenericTasks;
      return true;
    });

    // sort in progress tasks once more so current user's tasks are on top
    const sortedTasks = {
      toDoTasks: todoTasksFiltered,
      inProgressTasks: _.orderBy(
        inProgressTasksFiltered,
        function (task) {
          if (task.assignedTo.email === user.email) {
            return 1;
          }

          return 0;
        },
        'desc',
      ),
    };

    const hasUserClaimedMaxTasks = inProgressTasks.filter((x) => x.type === 'triage' && x.assignedTo.id === user.id).length >= maxClaimedTriageTasks;

    // return the sorted tasks object
    return { tasks: sortedTasks, hasUserClaimedMaxTasks };
  };

  const {
    data: { tasks, hasUserClaimedMaxTasks },
    isPending,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: ['openTasks'],
    queryFn: getOpenTasks,
    placeholderData: [],
    select: filterTasks,
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: true,
  });

  if (isPending) return <div>Loading...</div>;
  if (isError) return <span>Error: {error.message}</span>;

  const taskMutation = useMutation({
    // @ts-ignore
    mutationFn: ({ endpoint, taskId }) => {
      return sendPostRequest(endpoint, { taskId });
    },
    onSuccess: (data) => {
      console.log(data);
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
    onSettled: (data) => {
      setDisabledTasks((prev) => ({ ...prev, [data.jsonData.taskId]: false }));
    },
  });

  useEffect(() => {
    // get user's shift prefs on page load
    if (user.id !== null) {
      (async () => {
        // get user details & prefs
        const { data: userPrefs } = await sendPostRequest('/user-prefs', { qawId: user.id, page: '/triage' }).catch((e) => {
          throw new Error(e);
        });
        setUserShiftPrefs(userPrefs);
      })();
    }
  }, []);

  useEffect(() => {
    // update localStorage with filter settings
    localStorage.setItem('filterSettings', JSON.stringify({ showUserTasksOnly, showUserTodosOnly, showGenericTasks, showTriageTasks, showMessageTasks, showPromotionTasks }));
  }, [showUserTasksOnly, showUserTodosOnly, showMessageTasks, showGenericTasks, showTriageTasks, showPromotionTasks]);

  const allowedActions = ['/check', '/ignore', '/urgent', '/done'];
  function suiteAction(taskId, action) {
    if (!allowedActions.includes(action)) {
      throw new Error(`Action ${action} is not allowed. Allowed actions: ${allowedActions.join(',')}`);
    }

    // @ts-ignore
    taskMutation.mutate({ endpoint: action, taskId });
  }

  function toggleClaimed(taskId, doClaim, linkToOpen) {
    if (!taskId) {
      throw new Error('Task ID was undefined.');
    }

    if (doClaim) {
      if (linkToOpen) window.open(linkToOpen, '_blank');
      // @ts-ignore
      taskMutation.mutate({ endpoint: '/claim', taskId });
    } else {
      // @ts-ignore
      taskMutation.mutate({ endpoint: '/unclaim', taskId });
    }
  }

  function getShiftButtonText(text) {
    if (!enabledTeams.length) {
      return 'Please select a team';
    } else {
      let teamText;
      if (enabledTeams.length === 1) teamText = enabledTeams[0].teamName;
      else teamText = enabledTeams.length.toString();
      return `${text} Shift for ${teamText}${teamText.length <= 2 ? ' Teams' : ''}`; // hacky but works
    }
  }

  async function startShift(excludedTaskType) {
    try {
      setToast(<></>);
      setShowSpinner(true);
      // if messages or suites only selected, unsubscrbie from the other first
      if (excludedTaskType) await sendPostRequest('/task-unsubscribe', { qawId: user.id, taskType: excludedTaskType });

      const teamIds = enabledTeams.filter((x) => x.active && x.qawId !== 'cky0ip87x002509jo3exj9s4z').map((x) => x.qawId);
      const { data: newPrefs } = await sendPostRequest('/start-shift', { qawId: user.id, teamIds });

      setUserShiftPrefs(newPrefs);
      setShowSpinner(false);
      setToast(<Toast title={'Success!'} message={'Started Team Shift'} key={new Date().toISOString()} isSuccess={true} />);
    } catch (e) {
      setToast(<Toast title={'Ruh-roh'} message={`Something went wrong: ${e.message}`} key={new Date().toISOString()} isSuccess={false} />);
    }
  }

  async function endShift() {
    setToast(<></>);
    setShowSpinner(true);

    // end shift
    const { data: newPrefs } = await sendPostRequest('/end-shift', { qawId: user.id }).catch((e) => {
      throw new Error(e);
    });
    setUserShiftPrefs(newPrefs);

    // unclaim all claimed triage tasks and messages
    const claimedTasks = tasks.inProgressTasks.filter((x) => x.assignedTo.id === user.id && (x.type === 'triage' || x.subType === 'message'));
    claimedTasks.forEach((t) => {
      // @ts-ignore
      taskMutation.mutate({ endpoint: '/unclaim', taskId: t.id });
    });

    setShowSpinner(false);
    setToast(<Toast title={'Success!'} message={'Ended Team Shift'} key={new Date().toISOString()} isSuccess={true} />);
  }

  return (
    <div className="min-h-full px-4">
      {showSpinner && (
        <div className="fixed h-screen w-screen bg-gray-400 z-10 bg-opacity-50 -ml-5 -mt-6">
          <div className="fixed top-1/2 left-1/2">
            <img
              className="animate-spin"
              src="https://assets-global.website-files.com/6260298eca091b57c9cf188e/6260298eca091b8710cf18ea_logo.svg"
              alt="logo"
              width="40"
            />
            <h1 className="-ml-2 mt-2 text-white">Updating...</h1>
          </div>
        </div>
      )}

      <div className="pb-2">
        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-4">
          {triageStats.map((item) => (
            <div key={item.name} className="overflow-hidden rounded-lg bg-gray-50 px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">{item.stat}</dd>
            </div>
          ))}
        </dl>
      </div>

      <div className="pb-2">
        <div className="overflow-hidden rounded-lg bg-gray-50 px-4 pb-2 shadow flex flex-row justify-between items-start">
          <TeamSelector
            enabledTeams={enabledTeams}
            setEnabledTeams={setEnabledTeams}
            refetchQuery={refetch}
            teamTriageSummaries={teamTriageSummaries}
            activeShift={Boolean(userShiftPrefs?.suiteConfig?.teams?.length)}
            setToast={setToast}
          />
          <div className="flex items-center pt-2">
            <div id="button-container" className="flex flex-col justify-start">
              <div id="top-row-buttons" className={`inline-flex justify-end ${!userShiftPrefs?.suiteConfig?.teams?.length || 'hidden'}`}>
                <button
                  type="button"
                  className="w-max relative mt-2 inline-flex items-center gap-x-2 rounded-l-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white hover:bg-green-500"
                  disabled={enabledTeams.every((x) => !x.active)}
                  onClick={startShift}
                >
                  <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  {getShiftButtonText('Start')}
                </button>
                <Menu as="div" className="relative mt-2 -ml-px block">
                  <Menu.Button
                    className="relative inline-flex items-center rounded-r-md bg-green-600 px-2 py-2.5 border-l-[1px] border-white text-white hover:bg-green-500"
                    disabled={enabledTeams.every((x) => !x.active)}
                  >
                    <span className="sr-only">Open options</span>
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute overflow-visible right-0 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item key="messagesOnly">
                          {({ active }) => (
                            <button
                              type="button"
                              onClick={() => startShift('suite')}
                              className={classNames(
                                'w-full text-left',
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Messages Only
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item key="suitesOnly">
                          {({ active }) => (
                            <button
                              type="button"
                              onClick={() => startShift('message')}
                              className={classNames(
                                'overflow-visible w-full text-left',
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Suites Only
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div id="bottom-row-buttons" className={`flex justify-end ${userShiftPrefs?.suiteConfig?.teams?.length || 'hidden'}`}>
                <button
                  type="button"
                  className="w-max mt-2 inline-flex items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                  onClick={endShift}
                >
                  <XCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  {getShiftButtonText('End')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main 3 column grid */}
      <div className="relative grid grid-cols-1 items-start gap-4 lg:grid-cols-2 lg:gap-4">
        <div className="grid grid-cols-1 gap-4">
          <InvestigationColumn
            title="To Do"
            tasks={tasks.toDoTasks.filter((x) => (showUserTodosOnly ? x.assignedTo.id === user.id || !x.assignedTo.id : true))}
            toggleClaimed={toggleClaimed}
            user={user}
            suiteAction={suiteAction}
            setShowNotesModal={setShowNotesModal}
            setNotesModalData={setNotesModalData}
            setEditingNotes={setEditingNotes}
            showMessageTasks={showMessageTasks}
            setShowMessageTasks={setShowMessageTasks}
            showGenericTasks={showGenericTasks}
            setShowGenericTasks={setShowGenericTasks}
            showTriageTasks={showTriageTasks}
            setShowTriageTasks={setShowTriageTasks}
            hasUserClaimedMaxTasks={hasUserClaimedMaxTasks}
            showPromotionTasks={showPromotionTasks}
            setShowPromotionTasks={setShowPromotionTasks}
            primaryTeamOnly={primaryTeamOnly}
            setPrimaryTeamOnly={setPrimaryTeamOnly}
            showUserTodosOnly={showUserTodosOnly}
            setShowUserTodosOnly={setShowUserTodosOnly}
          />
        </div>

        <div className="grid grid-cols-1 gap-4">
          <InvestigationColumn
            title="In Progress"
            tasks={tasks.inProgressTasks.filter((x) => (showUserTasksOnly ? x.assignedTo.id === user.id : true))}
            toggleClaimed={toggleClaimed}
            user={user}
            suiteAction={suiteAction}
            setShowNotesModal={setShowNotesModal}
            setNotesModalData={setNotesModalData}
            setShowNewTaskModal={setShowNewTaskModal}
            setNewTaskModalData={setNewTaskModalData}
            setEditingNotes={setEditingNotes}
            showUserTasksOnly={showUserTasksOnly}
            showMessageTasks={showMessageTasks}
            setShowMessageTasks={setShowMessageTasks}
            setShowUserTasksOnly={setShowUserTasksOnly}
            showGenericTasks={showGenericTasks}
            setShowGenericTasks={setShowGenericTasks}
            showTriageTasks={showTriageTasks}
            setShowTriageTasks={setShowTriageTasks}
            hasUserClaimedMaxTasks={hasUserClaimedMaxTasks}
            showPromotionTasks={showPromotionTasks}
            setShowPromotionTasks={setShowPromotionTasks}
            primaryTeamOnly={primaryTeamOnly}
            setPrimaryTeamOnly={setPrimaryTeamOnly}
          />
        </div>
      </div>
      <EnvNotesModal
        refetch={refetch}
        showNotesModal={showNotesModal}
        setShowNotesModal={setShowNotesModal}
        notesModalData={notesModalData}
        setNotesModalData={setNotesModalData}
        editingNotes={editingNotes}
        setEditingNotes={setEditingNotes}
        setToast={setToast}
        tasks={[...tasks.toDoTasks, ...tasks.inProgressTasks]}
      />
      <NewTaskModal
        showNewTaskModal={showNewTaskModal}
        setShowNewTaskModal={setShowNewTaskModal}
        newTaskModalData={newTaskModalData}
        setNewTaskModalData={setNewTaskModalData}
        suiteAction={suiteAction}
        setToast={setToast}
      />
      {toast}
    </div>
  );
}
