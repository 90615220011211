// @ts-nocheck
import { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell, ReferenceLine, Label } from 'recharts';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment/moment';
import _ from 'lodash';
import { Switch } from '@headlessui/react';
import { classNames } from '../InvestigationBoard/helpers';
import { MagnifyingGlassCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { getSuiteInfo } from './getSuiteInfo';
import WolfLoader from '../WolfLoader/WolfLoader';

function getManyTriageLines(triageEvents) {
  let allLines = [];
  // To store each bug with its corresponding main count and sub count
  let issueCountMap = new Map();

  // This will increment only when we encounter a new issue ID
  let currentCount = 1;

  for (let item of triageEvents) {
    let issueId = item.issueId;
    let issueData;

    if (!issueCountMap.has(issueId)) {
      // If this is the first instance of the bug, initialize count and sub count
      issueData = {
        mainCount: currentCount,
        subCount: 1,
      };
      issueCountMap.set(issueId, issueData);
      // Only increment the main count for a new bug
      currentCount++;
    } else {
      // If this is a subsequent instance, increment the sub count only
      issueData = issueCountMap.get(issueId);
      issueData.subCount += 1;
    }

    let fromSuiteStartInMinutes = item.timeFromSuiteStartInMinutes - 420;

    let countLabel = `${issueData.mainCount}-${issueData.subCount}`;
    let status = item.status;
    // Red for first instance, Pink for subsequent
    let colour = '#DC143C';
    if (status === 'Bug') {
      colour = issueData.subCount === 1 ? '#DC143C' : '#FFC0CB';
    } else {
      colour = issueData.subCount === 1 ? '#87CEEB' : '#B0E2FF';
    }

    let lineItem = getLines(fromSuiteStartInMinutes, `${status} #${countLabel}`, colour, 'left');
    allLines.push(lineItem);
  }

  return allLines;
}

function getManyLines(versions) {
  let allLines = [];

  let count = 1;
  for (let item of _.orderBy(versions, 'versionStartedOffset')) {
    let lineItem = getLines(item.versionStartedOffset, `Version update #${count}`, '#00CED1', 'left');
    allLines.push(lineItem);

    count++;
  }

  return allLines;
}

function getLines(xAxesValue, text, color = '#0047AB', textSide = 'right') {
  let lines = [
    <ReferenceLine
      key={text + '_bottom'}
      x={xAxesValue}
      label={
        <Label position={'bottom'} fill={color} fontSize={14} offset={24}>
          {xAxesValue.toFixed(2)}
        </Label>
      }
      stroke={color}
      strokeDasharray="3 3"
    />,
    <ReferenceLine
      key={text + '_middle'}
      x={xAxesValue}
      label={
        <Label position={textSide} angle={90} fill={color} fontSize={24} offset={20}>
          {text}
        </Label>
      }
      stroke={color}
      strokeDasharray="3 3"
    />,
  ];

  return lines;
}

export default function FlowExplorer() {
  const [fixedHeight, setFixedHeight] = useState(true);
  const [showBotLines, setShowBotLines] = useState(true);
  const [showQAELines, setShowQAELines] = useState(true);
  const [showBugLines, setShowBugLines] = useState(true);
  const [showVersionLines, setShowVersionLines] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  const [sideItems, setSideItems] = useState([]);
  const [selectedHover, setSelectedHover] = useState();
  const [distributionHover, setDistributionHover] = useState();

  const { suiteId } = useParams();

  const {
    data: suiteData,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: ['suiteData', suiteId],
    queryFn: () => getSuiteInfo({ params: { suiteId } }),
    keepPreviousData: true,
  });

  if (isPending) return <WolfLoader />;
  if (isError) return <span>Error: {error.message}</span>;


  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let payloadData = payload[1].payload;
      let runId = payloadData.runId;
      let runAttemptId = payloadData.runAttemptId;
      let creatorEmail = payloadData.creator_email;
      let lastAttempt = payloadData.last_attempt;
      let lastDataPoint = payloadData.time_since_last_event;
      let triage = payloadData.triage;
      let triagedBy = payloadData.triagedBy;
      let version = payloadData.version;
      let isQaWolfBrowser = payloadData.is_qawolf_browser;
      let superseedingSuiteId = payloadData.superSedingSuiteId;

      let returnElement = (
        <div className="custom-tooltip bg-white">
          <h1>{label}</h1>
          {runId && <p>{`Run ID: ${runId}`}</p>}
          {runAttemptId && <h2>Attempt ID: {runAttemptId}</h2>}
          <p>{`Duration (mins): ${_.ceil(payload[1].value.toFixed(2))} minutes`}</p>
          <p>{`From Start (mins): ${_.ceil(payload[0].value.toFixed(2))} minutes`}</p>

          {lastDataPoint && <p>{`From last event: ${lastDataPoint.toFixed(2)} minutes`}</p>}
          {triage && <p>{`Triage took: ${triage.toFixed(2)} minutes`}</p>}
          {triagedBy && <p>{`Triaged by: ${triagedBy}`}</p>}
          {creatorEmail && <p>{`Creator Email: ${creatorEmail}`}</p>}
          {lastAttempt && <p>{`Last Attempt: ${lastAttempt}`}</p>}
          {version && (
            <div>
              <p>{`New version detected - update #${version.sequence}`}</p>
              <ul>
                <li>{`Published At: ${version.versionPublishedAt}`}</li>
                <li>{`Published By: ${version.versionPublishedBy}`}</li>
                <li>{`Published Note: ${version.versionPublishNote}`}</li>
              </ul>
            </div>
          )}
          {isQaWolfBrowser === 'true' && <p>{`This run attempt was from the QA Wolf Browser.`}</p>}
          {superseedingSuiteId && <p>{`Superseeding Suite: ${superseedingSuiteId}`}</p>}
        </div>
      );

      return returnElement;
    }

    return null;
  };

  function formatJsonDataObject(number, label, color) {
    return {
      label,
      color,
      number: number,
      percent: (number / suiteData.numberOfRuns) * 100,
    };
  }

  let distributionData = {
    passedFirst: formatJsonDataObject(suiteData.numberOfRunsPassingOnFirstAttempt, 'First Attempts:', '#70CF11'),
    autoTriaged: formatJsonDataObject(suiteData.numberOfRunsAutoTriaged, 'Auto Triaged Attempts (Already bugged):', '#DAF7A6'),
    passedSecond: formatJsonDataObject(suiteData.numberOfRunsPassingOnSecondAttempt, 'Second Attempts:', '#FFC300'),
    passedThird: formatJsonDataObject(suiteData.numberOfRunsPassingOnThirdAttempt, 'Third Attempts:', '#FF5733'),
    qaeTriage: formatJsonDataObject(suiteData.numberOfRunsPassingOnQaeFix, 'QA Triaged with Changes:', '#008080'),
    qaeFlake: formatJsonDataObject(suiteData.numberOfRunsPassingOnQaeFlake, 'QA Passed on Retry:', '#96DED1'),
    newlyBugged: formatJsonDataObject(suiteData.numberOfRunsTriagedAsBug, 'Newly Bugged:', '#DC143C'),
    maintenance: formatJsonDataObject(suiteData.numberOfRunsTriagedAsMaintenance, 'Maintenance:', '#DDCCFF'),
    doNotInvestigate: formatJsonDataObject(suiteData.numberOfRunsTriagedAsDoNotInvestigate, 'Marked as Do Not Investigate:', '#741b47'),
    supersededRuns: formatJsonDataObject(suiteData.numberOfSupersededRuns, 'Superseded Runs:', '#5b5b5b'),
    canceled: formatJsonDataObject(suiteData.numberOfRunsCanceled, 'Cancelled Attempts:', '#bcbcbc'),
    failed: formatJsonDataObject(suiteData.failureRate, 'Failed Attempts:', '#000000'),
  };

  let distributionBarData = [{ name: 'Distribution Data' }];
  for (let key of Object.keys(distributionData)) {
    distributionBarData[0][key] = distributionData[key].percent;
    distributionBarData[0][`${key}_label`] = distributionData[key].percent.toFixed(2);
  }

  const suiteCount = suiteData.supersededSuiteIds.length;
  const suiteText = suiteCount === 1 ? 'another suite' : 'other suites';
  let superseedingOrMovedMessage = '';

  if (suiteData.isSuperseded && suiteData.isMovedToNewerSuite) {
    superseedingOrMovedMessage = `Runs were superseded and moved to ${suiteText}`;
  } else if (suiteData.isSuperseded) {
    superseedingOrMovedMessage = `Suite was superseded to ${suiteText}`;
  } else if (suiteData.isMovedToNewerSuite) {
    superseedingOrMovedMessage = `Runs were moved to ${suiteText}`;
  }

  const customerName = suiteData.customerName || suiteData.customer.officialName;

  return (
    <div className="flex h-full flex-col">
      <div className="ml-14">
        <div className="grid grid-cols-10 gap-2">
          <div className="col-span-5">
            <span className="font-medium text-gray-900">{customerName}</span>
            <a
              className="ml-4 font-medium text-blue-600 hover:underline"
              href={`https://app.qawolf.com/${suiteData.customerSlug}/environments/${suiteData.environmentId}/runs/${suiteData.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {suiteData.triggerName}
            </a>
            <span className="ml-4">{moment(suiteData.startTimeString).format('llll')} (PST)</span>
          </div>
          <div className="col-span-5 flex justify-end">
            <div className="">
              <Switch.Group as="div" className="flex items-center pr-4">
                <Switch
                  checked={fixedHeight}
                  onClick={() => setFixedHeight(!fixedHeight)}
                  className={classNames(
                    fixedHeight ? 'bg-blue-400' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      fixedHeight ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Fit to Screen</span>
                </Switch.Label>
              </Switch.Group>
            </div>
            <div className="">
              <Switch.Group as="div" className="flex items-center pr-4">
                <Switch
                  checked={showBotLines}
                  onClick={() => setShowBotLines(!showBotLines)}
                  className={classNames(
                    showBotLines ? 'bg-blue-400' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      showBotLines ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Retry bot Lines</span>
                </Switch.Label>
              </Switch.Group>
            </div>
            <div className="">
              <Switch.Group as="div" className="flex items-center pr-4">
                <Switch
                  checked={showQAELines}
                  onClick={() => setShowQAELines(!showQAELines)}
                  className={classNames(
                    showQAELines ? 'bg-blue-400' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      showQAELines ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">QAE Lines</span>
                </Switch.Label>
              </Switch.Group>
            </div>
            <div className="">
              <Switch.Group as="div" className="flex items-center pr-4">
                <Switch
                  checked={showBugLines}
                  onClick={() => setShowBugLines(!showBugLines)}
                  className={classNames(
                    showBugLines ? 'bg-blue-400' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      showBugLines ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Bug Lines</span>
                </Switch.Label>
              </Switch.Group>
            </div>
            <div className="">
              <Switch.Group as="div" className="flex items-center pr-4">
                <Switch
                  checked={showVersionLines}
                  onClick={() => setShowVersionLines(!showVersionLines)}
                  className={classNames(
                    showVersionLines ? 'bg-blue-400' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      showVersionLines ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Version Lines</span>
                </Switch.Label>
              </Switch.Group>
            </div>
          </div>
        </div>
        <div>
          {suiteData.taskClaimedBy && <span>Investigated By: {suiteData.taskClaimedBy}</span>}
          {suiteData.supportedBy && <span> | Supported By: {suiteData.supportedBy.join(', ')}</span>}
        </div>
        <div className="pt-4">
          <div>
            <div>
              {suiteData.isCompleted && (
                <p className="font-bold text-green-700 uppercase">This Suite has been fully Triaged!</p>
              )}
              {suiteData.numberOfSupersededRuns !== 0 && (
                <p className="font-bold text-orange-700 uppercase">
                  {superseedingOrMovedMessage}:
                  {suiteData.supersededSuiteIds.map((suiteId, index) => (
                    <Link
                      key={suiteId}
                      to={`/flow-explorer/${suiteId}`}
                      className="text-blue-500 hover:text-blue-800 ml-2"
                    >
                      {index > 0 && ', '}
                      Suite {index + 1}
                    </Link>
                  ))}
                </p>
              )}
              {suiteData.failureRate !== 0 && (
                <p className="font-bold text-red-700 uppercase">Not fully investigated! {suiteData.failureRate} Failures.</p>
              )}
              <p className="font-bold">
                Run time: {suiteData.runTimeInMinutes?.toFixed(2) || '-'} minutes
                <span className="text-sm text-gray-600" style={{ display: 'inline', marginLeft: '8px', fontWeight: 'normal' }}>
                  (All First Attempts Completed)
                </span>
              </p>
              {/* <p className="font-bold">
                All Auto Attempts: {suiteData.timeToCompleteAllAutomaticAttemptsInMinutes?.toFixed(2) || '-'} minutes
                <span className="text-sm text-gray-600" style={{ display: 'inline', marginLeft: '8px', fontWeight: 'normal' }}>
                  (All Auto Attempts Completed)
                </span>
              </p> */}
              <p className="font-bold">
                Triage overhead:{' '}
                {suiteData.totalTimeInMinutes && suiteData.runTimeInMinutes ? (suiteData.totalTimeInMinutes - suiteData.runTimeInMinutes)?.toFixed(2) || '-' : '-'}{' '}
                minutes
                <span className="text-sm text-gray-600" style={{ display: 'inline', marginLeft: '8px', fontWeight: 'normal' }}>
                  (QAE + retries)
                </span>
              </p>
              <p className="font-bold">Total time: {suiteData.totalTimeInMinutes?.toFixed(2) || '-'} minutes</p>
              {suiteData.successPercent && (
                <p className="font-bold">Success Rate: {suiteData.successPercent.toFixed(2)}%
                  <span className="text-sm text-gray-600" style={{ display: 'inline', marginLeft: '8px', fontWeight: 'normal' }}>
                    (First time pass + Autotriaged)
                  </span></p>
              )}
              {suiteData.flakePercent && (
                <p className="font-bold">Flake Rate: {suiteData.flakePercent.toFixed(2)}% </p>
              )}
              {suiteData.deterministicTestsPercent && (
                <p className="font-bold">Deterministic Test Percentage: {suiteData.deterministicTestsPercent.toFixed(2)}% </p>
              )}
              {suiteData.deterministicTestsPercent && suiteData.successPercent && (
                <p className="font-bold">Change Rate: {(suiteData.deterministicTestsPercent - suiteData.successPercent).toFixed(2)}% </p>
              )}
            </div>
          </div>
          <p style={{ paddingTop: '15px' }}>
            Runs: {suiteData.numberOfRuns} | Run Attempts: {suiteData.numberOfRunAttempts} | Success Rate:
            {(distributionData.passedFirst.percent + distributionData.autoTriaged.percent + distributionData.canceled.percent).toFixed(2)}%
          </p>
          <div className="-ml-1.5" style={{ width: '400px' }}>
            <BarChart layout="vertical" width={400} height={50} data={distributionBarData}>
              <XAxis type="number" dataKey="uv" domain={[0, 100]} hide={true} />
              <YAxis type="category" dataKey="name" hide={true} />
              {Object.keys(distributionData).map((objectKey) => {
                let objectWithData = distributionData[objectKey];
                return (
                  <Bar
                    key={objectKey}
                    dataKey={objectKey}
                    stackId="a"
                    fill={objectWithData.color}
                    onMouseOver={() => {
                      setDistributionHover(objectWithData);
                    }}
                    onClick={() => {
                      let data = suiteData.runsByLastAttemptStatus[objectKey];
                      setSideItems(data);
                    }}
                  />
                );
              })}
            </BarChart>
          </div>
          {distributionHover ? (
            <p>
              {distributionHover.label} {distributionHover.number} | {distributionHover.percent.toFixed(2)}%
            </p>
          ) : (
            <p>Hover to get data details, click on a group to search it</p>
          )}
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4 pt-4">
        <div className="flex items-start col-span-3">
          <BarChart
            layout="vertical"
            width={1200}
            height={fixedHeight ? 700 : 20 * suiteData.flatOrderedData.length}
            data={suiteData.flatOrderedData}
            margin={{ top: 5, right: 30, left: 50, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="10 5" horizontal={false} />
            <Tooltip content={CustomTooltip} />
            <XAxis type="number" dataKey="uv" domain={[0, _.ceil(suiteData.totalTimeInMinutes)]} />
            <YAxis type="category" dataKey="name" hide={true} />

            {showVersionLines && getManyLines(suiteData.cleanVersions)}

            {showBugLines && suiteData.triageEvents && getManyTriageLines(suiteData.triageEvents)}

            {showBotLines && suiteData.runTimeInMinutes && getLines(suiteData.runTimeInMinutes, 'First Run Finished')}
            {showBotLines && suiteData.timeToCompleteSecondAttemptInMinutes && getLines(suiteData.timeToCompleteSecondAttemptInMinutes, 'Second Run Finished')}
            {showBotLines && suiteData.timeToCompleteThirdAttemptInMinutes && getLines(suiteData.timeToCompleteThirdAttemptInMinutes, 'Third Run Finished')}

            {showQAELines && suiteData.minutesUntilFirstQaeAttempt && getLines(suiteData.minutesUntilFirstQaeAttempt, 'QA Attempts Started', '#E1C16E', 'right')}
            {showQAELines && suiteData.minutesUntilTriageStarted && getLines(suiteData.minutesUntilTriageStarted, 'QAE Triage Started', '#FF7F50', 'left')}

            <Bar dataKey="pv" stackId="a" fill="transparent" />
            <Bar dataKey="uv" stackId="a">
              {suiteData.flatOrderedData.map((entry, index) => {
                let strokeColor = undefined;
                let barColor = '#7393B3';
                if (entry.attempt) {
                  barColor = entry.status === 'pass' ? '#097969' : entry.status === 'created' ? '#fcd12a' : '#ff0000';
                } else if (entry.isTriage) {
                  barColor = '#FF4500';
                }

                if (entry.autoTriaged) {
                  barColor = '#702963';
                }

                let isQaWolfBrowser = entry.is_qawolf_browser;
                if (isQaWolfBrowser === 'true') {
                  strokeColor = '#1616FF';
                  if (entry.isHowl9000) {
                    barColor = entry.status === 'pass' ? '#82E0AA' : '#F5B7B1';
                  }
                }

                if (entry.attempt && entry.runAttemptId === selectedHover?.runAttemptId) {
                  barColor = '#C29B0C';
                }

                if (entry.isCustomer) {
                  barColor = '#A04000';
                }

                if (entry.isCanceled) {
                  barColor = '#ABB2B9';
                }

                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={barColor}
                    stroke={strokeColor}
                    strokeWidth={5}
                    onClick={() => {
                      if (entry.attempt && sideItems.find((x) => x.runAttemptId === entry.runAttemptId) === undefined) {
                        let newSideItems = [...sideItems, entry];
                        setSideItems(newSideItems);
                      }
                    }}
                  />
                );
              })}
            </Bar>
            {selectedHover && (
              <ReferenceLine
                y={selectedHover.name}
                stroke="#C29B0C"
                label={<Label value="Selected" fill={'#C29B0C'} offset={5} position="insideBottomRight" />}
              />
            )}
          </BarChart>
        </div>
        <div className="relative">
          <div className="sticky top-5">
            <span>Click on an attempt on the graph to pin it:</span>
            <div className="pr-8 flex">
              <input
                type="text"
                name="search"
                id="search"
                className="block w-3/4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                placeholder="run attempt ID"
                onChange={(e) => {
                  let searchValue = e.target.value;
                  setSearchQuery(searchValue);
                }}
              ></input>
              <button
                type="button"
                className="ml-3 inline-flex items-center rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={() => {
                  let searchedWfRun = suiteData.flatOrderedData.filter(
                    (x) => x?.runAttemptId === searchQuery || (x?.runAttemptId && x?.name?.toLowerCase().includes(searchQuery.toLowerCase())),
                  );
                  if (!searchedWfRun.length) {
                    alert(`No WFs found with ID ${searchQuery}`);
                    return;
                  }

                  let uniqueFound = searchedWfRun.filter((x) => !sideItems.map((y) => y.runAttemptId).includes(x.runAttemptId));

                  let newSideItems = [...sideItems, ...uniqueFound];
                  setSideItems(newSideItems);

                  let lastItem = _.last(searchedWfRun);
                  setSelectedHover(lastItem);
                }}
              >
                <MagnifyingGlassCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="ml-2 inline-flex items-center rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={() => {
                  setSideItems([]);
                  setSelectedHover(undefined);
                }}
              >
                <XMarkIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            {sideItems.map((item) => {
              return (
                <div
                  className="relative max-w-sm my-2 p-4 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-50"
                  key={item.runAttemptId}
                  data-run-attempt-id={item.runAttemptId}
                  onMouseEnter={(x) => {
                    let currentId = x.currentTarget.attributes['data-run-attempt-id']?.value;
                    let searchedWfRun = _.last(suiteData.flatOrderedData.filter((x) => x?.runAttemptId === currentId));
                    if (currentId && searchedWfRun) {
                      setSelectedHover(searchedWfRun);
                    }
                  }}
                  onMouseLeave={() => {
                    setSelectedHover(undefined);
                  }}
                >
                  <button
                    type="button"
                    className="absolute top-1 right-0 bg-white rounded-lg p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    onClick={() => {
                      let newSideItems = sideItems.filter((x) => x.runAttemptId !== item.runAttemptId);
                      setSideItems(newSideItems);
                      setSelectedHover(undefined);
                    }}
                  >
                    <span className="sr-only">Close menu</span>
                    <svg
                      className="h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>

                  <a
                    href={`https://app.qawolf.com/${suiteData.customerSlug}/runs/${item.runId}/attempt/${item.runAttemptId}`}
                    className="font-medium text-blue-600 hover:underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.name}
                  </a>
                  <div>
                    <p>Run ID: {item.runId}</p>
                    <p>Run Attempt ID: {item.runAttemptId}</p>
                  </div>
                  <div className="grid grid-cols-5 gap-2">
                    <div>
                      <a
                        href={`https://app.qawolf.com/${suiteData.customerSlug}/runs/${item.runId}/trace`}
                        className="font-medium text-blue-600 hover:underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Trace
                      </a>
                    </div>
                    <div className="col-span-2">
                      <p>{`Execution: ${_.ceil(item.uv)} min`}</p>
                    </div>
                    <div className="col-span-2">
                      <p>{`Wait: ${_.ceil(item.pv)} min`}</p>
                    </div>
                    {item.superSedingSuiteId &&
                      <div>
                        <Link
                          key={suiteId}
                          to={`/flow-explorer/${item.superSedingSuiteId}`}
                          className="font-medium text-blue-600 hover:underline"
                        >Superseding Suite</Link>
                      </div>
                    }
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
