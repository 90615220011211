import { useState } from 'react';

import Spinner from '../../Layout/Spinner';
import { TimeTrackingMetricsTable } from './TimeTrackingMetricsTable';

import { groupAndSortEvents } from './timeTrackingHelpers';
import { sendGetRequest } from '../../../utils/network';

export function QaeTimeTrackingCard({ events, qae, filters }) {
  const [qaeEvents, setQaeEvents] = useState(groupAndSortEvents(events));
  const [showDetails, setShowDetails] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  function handleShowDetails() {
    setShowDetails((prev) => !prev);
  }

  async function handleRefreshQaeMetrics() {
    console.log(`Refreshing metrics for ${qae.name}!`);
    setIsButtonLoading((prev) => !prev);
    const { data: qaeEvents } = await sendGetRequest(
      `/time-tracking-metrics/qae/${qae.qawId}?gte=${filters.gte}&lte=${filters.lte}&silenceAlert=true`,
    );
    setQaeEvents(groupAndSortEvents(qaeEvents.events));
    setIsButtonLoading((prev) => !prev);
    console.log(qaeEvents);
  }

  return (
    <div className="flex flex-col border rounded-md w-full px-4">
      <div className="flex items-center w-full my-1">
        <div className="flex flex-1 pl-1 items-center justify-start">
          <img className="h-10 w-10 rounded-full" src={qae.avatar48} alt={`${qae.name}`} title={`${qae.team}`} />
          <p className="pl-2 flex text-base font-semibold leading-6 text-gray-900 justify-start w-4/6">{qae.name}</p>
        </div>
        <div className="qae__button__container flex space-x-1 flex-1 justify-end">
          <button className="border bg-gray-50 shadow p-1 m-2 rounded-md flex justify-center items-center h-10 w-1/2" onClick={handleShowDetails}>
            {`${showDetails ? 'Hide' : 'Show'} Details`}
          </button>
          <button
            className="border bg-gray-50 shadow p-1 m-2 rounded-md flex justify-center items-center h-10 w-1/2"
            onClick={handleRefreshQaeMetrics}
          >
            {isButtonLoading ? <Spinner /> : 'Refresh Metrics'}
          </button>
        </div>
      </div>
      {qaeEvents && showDetails && (
        <div className="border bg-gray-50 rounded-md mb-2 overflow-auto">
          <TimeTrackingMetricsTable events={qaeEvents} qae={qae} />
        </div>
      )}
    </div>
  );
}
