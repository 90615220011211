import { useQuery } from '@tanstack/react-query';
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { sendGetRequest } from '../../utils/network';
import ExportCustomerArrButton from './utils/ExportCustomerARR';
import ExportQaeCostButton from './utils/ExportQaeCost';
import WolfLoader from '../WolfLoader/WolfLoader';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Header from '../Layout/Header';
import Tabs from '../UtilityComponents/TabComponents/tabs';
import ExecutiveOverviewBoard from './ExecutiveOverviewBoard';

export default function ExecutiveDashboard() {
  const defaultTabs = [
    { type: 'Team View', tableData: {}, current: true },
    { type: 'Customer View', tableData: {}, current: false },
  ];

  // get and parse search params
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsMap = Object.fromEntries([...searchParams]);
  const [tabs, setTabs] = useState(() => paramsMap.activeTab ? defaultTabs.map((tab) => ({ ...tab, current: tab.type === paramsMap.activeTab })) : defaultTabs);

  // handle init and update to search params
  useEffect(() => {
    // set active tab
    searchParams.set('activeTab', tabs.find((tab) => tab.current).type);
    setSearchParams(searchParams);
  }, [ searchParams, tabs ]);

  const {
    data: qawUserData,
    isLoading: isLoading_getQawUserData,
    isError: isError_getQawUserData,
  } = useQuery({
    queryKey: ['getQawUserData'],
    queryFn: () => sendGetRequest('/list-users'),
  });
  
  const {
    data: customerData,
    isLoading: isLoading_getCustomerData,
    isError: isError_getCustomerData,
  } = useQuery({
    queryKey: ['getCustomerData'],
    queryFn: () => sendGetRequest('/tasks-by-team'),
  });
   
  if (isLoading_getQawUserData || isLoading_getCustomerData) return <WolfLoader />;
  if (isError_getQawUserData || isError_getCustomerData) return <div>Error</div>;


  const annualHostingCosts  = 70_000 * 12;
  const annualQaeBenefitsCosts = 6_000;

  const allQaEngineeringEEs = qawUserData.data;
  const managersOnly = allQaEngineeringEEs.filter((user) => user.isManager);
  const leadsOnly = allQaEngineeringEEs.filter((user) => user.isLead);
  const qaEngineersOnly = allQaEngineeringEEs.filter((user) => user.isQAE && user.team);
  const totalCost = annualHostingCosts + allQaEngineeringEEs.reduce((acc, user) => acc + user.salary + annualQaeBenefitsCosts, 0);
  
  const allCustomersData = Object.values(customerData.data);
  const totalArr = allCustomersData.reduce((acc, customer) => acc + customer.arr, 0);
  const totalTestsUnderContract = allCustomersData.reduce((acc, customer) => acc + customer.stepLimit, 0);
  const totalTestsActive = allCustomersData.reduce((acc, customer) => acc + customer.metrics.tests.active, 0);
  const totalTestsBugged = allCustomersData.reduce((acc, customer) => acc + customer.metrics.tests.bugged, 0);
  const totalTestsMaintenance = allCustomersData.reduce((acc, customer) => acc + customer.metrics.tests.maintenance, 0);
  
  const teamViewTableData = () => leadsOnly.map((lead) => {
    const teamCost = () => {
      // teamQaeEngCost is the sum of all QA Engineers salaries on the team
      const teamQaeCost = qaEngineersOnly.filter((user) => user.team.id === lead.team.id).reduce((acc, user) => acc + user.salary + annualQaeBenefitsCosts, 0);
      // leadCost is the leads salary
      const teamLeadCost = lead.salary + annualQaeBenefitsCosts;
      // managerCost is the managers salary divided by the number of teams they manage
      const managerCostPerTeam = (managersOnly.find((manager) => manager.team.id === lead.team.packId).salary + annualQaeBenefitsCosts) / leadsOnly.filter((lead) => lead.team.packId === lead.team.packId).length;
      // directorCost is the directors salary divided by the number of total teams
      const directorCostPerTeam = (allQaEngineeringEEs.find((user) => user.name === "Eric Eidelberg").salary + annualQaeBenefitsCosts) / leadsOnly.length;
      const hostingCostPerTeam = annualHostingCosts / leadsOnly.length;
      return teamQaeCost + teamLeadCost + managerCostPerTeam + directorCostPerTeam + hostingCostPerTeam;
    };

    const teamAverageActiveTestsPerQae = () => {
      return allCustomersData.filter((customer) => customer.qaLead.id === lead.qawId).reduce((acc, customer) => acc + customer.metrics.tests.active, 0) / (qaEngineersOnly.filter((user) => user.team.id === lead.team.id).length);
    };

    return {
      teamName: lead.team.name,
      teamId: lead.team.id,
      packId: lead.team.packId,
      packManager: managersOnly.find((manager) => manager.team.id === lead.team.packId),
      teamArr: Math.round(allCustomersData.filter((customer) => customer.qaLead.id === lead.qawId).reduce((acc, customer) => acc + customer.arr, 0)),
      teamTestsUnderContract: allCustomersData.filter((customer) => customer.qaLead.id === lead.qawId).reduce((acc, customer) => acc + customer.metrics.tests.expected, 0),
      teamActiveTests: allCustomersData.filter((customer) => customer.qaLead.id === lead.qawId).reduce((acc, customer) => acc + customer.metrics.tests.active, 0),
      teamTestsInMaintenance: allCustomersData.filter((customer) => customer.qaLead.id === lead.qawId).reduce((acc, customer) => acc + customer.metrics.tests.maintenance, 0),
      teamTestsBugged: allCustomersData.filter((customer) => customer.qaLead.id === lead.qawId).reduce((acc, customer) => acc + customer.metrics.tests.bugged, 0),
      teamSize: (qaEngineersOnly.filter((user) => user.team.id === lead.team.id).length + leadsOnly.filter((user) => user.team.id === lead.team.id).length),
      teamAverageActiveTestsPerQae: Math.round(teamAverageActiveTestsPerQae()),
      teamCost: teamCost(),
      teamMargin: Math.round(allCustomersData.filter((customer) => customer.qaLead.id === lead.qawId).reduce((acc, customer) => acc + customer.arr, 0) - teamCost()),
      teamCustomers: allCustomersData.filter((customer) => customer.qaLead.id === lead.qawId),
    };
  });
  
  const customerViewTableData = () => allCustomersData.map((customer) => {
    const teamName = leadsOnly.find((lead) => lead.qawId === customer.qaLead.id)?.team?.name? leadsOnly.find((lead) => lead.qawId === customer.qaLead.id).team.name : 'No Team';

    return {
      customerName: customer.name ? customer.name : 'No Name',
      team: teamName,
      lead: customer.qaLead?.name ? customer.qaLead.name : 'No Lead',
      manager: customer.qaManager?.name ? customer.qaManager.name : 'No Manager',
      csm: customer.csm?.name ? customer.csm.name : 'No CSM',
      arr: customer.arr ? customer.arr : 0,
      testsUnderContract: customer.metrics.tests.expected ? customer.metrics.tests.expected : 0,
      testsActive: customer.metrics.tests.active ? customer.metrics.tests.active : 0,
      testsInMaintenance: customer.metrics.tests.maintenance ? customer.metrics.tests.maintenance : 0,
      testsBugged: customer.metrics.tests.bugged ? customer.metrics.tests.bugged : 0,
    };
  });
  
  tabs.find((tab) => tab.type === 'Team View').tableData = teamViewTableData();
  tabs.find((tab) => tab.type === 'Customer View').tableData = customerViewTableData();

  const totalMargin = Number(totalArr) - Number(totalCost);

  const marginData = [
    {
      totalArr: totalArr,
      totalCost: totalCost,
      annualHostingCosts: annualHostingCosts,
      totalMargin: totalMargin,
    },
  ];

  const currData = marginData[0];

  const workflowStatusData = [
    { name: 'Active', value: totalTestsActive - totalTestsBugged - totalTestsMaintenance },
    { name: 'Maintenance', value: totalTestsMaintenance },
    { name: 'Bugged', value: totalTestsBugged },
  ];
  
  // Colors for each section of the pie chart
  const COLORS = ['#4545E5', '#9EAFF0', '#DB4B4B'];


  return (
    <div className="min-h-full p-4">
      <div className="pb-2">
        <div className="flex flex-auto flex-row">
          <dl className="flex flex-auto order-1 h-1 w-full flex-row gap-5 flex-wrap">
            <div key="totalArr" title="Total sum of all Customer's ARR" className="relative overflow-hidden rounded-lg bg-gray-50 shadow sm:p-6">
              <ExportCustomerArrButton customerData={allCustomersData} />
              <dt className="truncate text-sm font-medium text-gray-500">Total ARR</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">
                {currData.totalArr.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </dd>
            </div>
            <div key="totalCost" title="Total sum of Hosting Costs and all Salaries (plus benefits) of QAEs, Leads, Managers, and the Director of Engineering" className="relative overflow-hidden rounded-lg bg-gray-50 shadow sm:p-6">
              <ExportQaeCostButton qaEngineerData={allQaEngineeringEEs} qaBenefitsAmount={annualQaeBenefitsCosts} />
              <dt className="truncate text-sm font-medium text-gray-500">Total QA Eng. Cost</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">
                {currData.totalCost.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </dd>
            </div>
            <div key="totalHostingCost" title="Annual Hosting Costs" className="relative rounded-lg bg-gray-50 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">Annual Hosting Costs</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">
                {currData.annualHostingCosts.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </dd>
            </div>
            <div key="totalMarginDollar" title="Difference between Total ARR and Total Costs" className="relative rounded-lg bg-gray-50 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">Total Margin $</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">
                {currData.totalMargin.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </dd>
            </div>
            <div key="totalMarginPercent" title="Percentage difference between Total ARR and Total Costs" className="relative rounded-lg bg-gray-50 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">Total Margin %</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">{Math.round((currData.totalMargin / totalArr) * 100)}%</dd>
            </div>
            <div key="totalTestsUnderContract" title="Amount of Tests Contracted with all Customers" className="relative rounded-lg bg-gray-50 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">Tests Under Contract:</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">{totalTestsUnderContract.toLocaleString()}</dd>
            </div>
            <div key="totalTestsActive" title="Amount of Total Tests that have been implemented, includes Bugged and Maintenance" className="relative rounded-lg bg-gray-50 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">Active Tests</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">{totalTestsActive.toLocaleString()}</dd>
            </div>
            <div key="totalTestsActivePercent" title="Percentage of Total Tests that have been implemented, includes Bugged and Maintenance" className="relative rounded-lg bg-gray-50 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">Active Tests %</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">
                {Math.round((totalTestsActive / totalTestsUnderContract) * 100)}%
              </dd>
            </div>
            <div key="averageActiveTestsPerQaeWithManagers" title="Total Active Tests divided by amount of Engineers (Including Leads and Managers)" className="relative rounded-lg bg-gray-50 shadow sm:p-6 w-70">
              <dt className="truncate text-sm font-medium text-gray-500">Avg. Active Tests per QAE (Incl. Managers)</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">{Math.round(totalTestsActive / allQaEngineeringEEs.length)}</dd>
            </div>
            <div key="averageActiveTestsPerQae" title="Total Active Tests divided by amount of Engineers (Including Leads, Excluding Managers)" className="relative rounded-lg bg-gray-50 shadow sm:p-6 w-70">
              <dt className="truncate text-sm font-medium text-gray-500">Avg. Active Tests per QAE (Excl. Managers)</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">{Math.round(totalTestsActive / qaEngineersOnly.length)}</dd>
            </div>
          </dl>
          <div className="order-2 w-7/12 h-auto">
            <ResponsiveContainer height={400}>
              <PieChart >
                <Pie
                  data={workflowStatusData}
                  labelLine={false}
                  label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {workflowStatusData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="py-4">
          <Header text={`${tabs.find((t) => t.current).type}`} />
          <Tabs tabs={tabs} setTabs={setTabs} activeTab={tabs.find((tab) => tab.current)} />
          <ExecutiveOverviewBoard activeTab={tabs.find((tab) => tab.current)} tableData={tabs.find((tab) => tab.current).tableData} />
        </div>
      </div>
    </div>
  );
}
