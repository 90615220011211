import { enrichTeamData } from '../helpers';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getCutomerData } from '../api';
import ClientSummaryBanner from './ClientSummaryBanner';
import ClientTasksTable from './ClientTasksTable';
import ImportantDatesWidget from './ImportantDatesWidget';
import StalenessWidget from './StalenessWidget';
import UtilizationWidget from './UtilizationWidget';
import WarningWidgets from './WarningWidgets';
import NotionButtons from './NotionButtons';
import CreationAndMaintenanceProgress from './CreationAndMaintenanceProgress';
import WolfLoader from '../../WolfLoader/WolfLoader';

/**
 * Renders a summary page for a specific client
 * @returns {import('react').JSX.Element} The ClientSummary component.
 */
export default function ClientSummary() {
  const { teamId } = useParams();
  const defaultTableState = {
    columnSizing: {},
    globalFilterFn: 'contains',
    groupCRandMRs: false,
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    rowSelection: {},
  };
  const [tableState, setTableState] = useState(defaultTableState);

  // Get specific data for this team, enriched with summary data
  const {
    data: team,
    isFetching,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ['teamsData'],
    queryFn: getCutomerData,
    placeholderData: [],
    select: (data) => {
      const team = data.find((x) => x.id === teamId);
      return enrichTeamData(team);
    },
    // Refetch the data if it hasn't already been cached
    refetchOnMount: ({ state }) => (!state.data.find((x) => x.id === teamId) ? 'always' : false),
  });

  if (isFetching || isLoading) return <WolfLoader />;
  if (isError) {
    return (
      <div className="rounded-xl border border-gray-200 bg-white mx-2 mb-4">
        <h1>{'Error loading client summary...'}</h1>
        <p>{error.message}</p>
      </div>
    );
  }

  return (
    <div className="rounded-xl border border-gray-200 bg-white mx-2 mb-4">
      <div className="w-full rounded-t-xl border-b border-gray-900/5 p-4 flex justify-between">
        <p className="truncate flex min-w-3/5 text-3xl font-normal text-gray-900" title={`${team.name} (${team.id})`}>
          {team.name}
        </p>
        <NotionButtons teamId={team.id} />
      </div>
      <dl className="-my-3 divide-y divide-gray-100 p-4 text-sm leading-6">
        <ClientSummaryBanner team={team} />
        <div className="flex justify-between w-full">
          <UtilizationWidget team={team} />
          <ImportantDatesWidget team={team} />
          <StalenessWidget team={team} />
        </div>
        <CreationAndMaintenanceProgress team={team} />
        <div className="w-full py-2">
          <WarningWidgets team={team} setTableState={setTableState} />
        </div>
      </dl>
      <ClientTasksTable tableState={tableState} setTableState={setTableState} defaultTableState={defaultTableState} />
    </div>
  );
}
