import _ from 'lodash';
import dayjs from 'dayjs';
const weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);

export const basicCategories = ['Tracked', 'OOO', 'Triage', 'Other', 'Overtime', 'Untracked', 'Weekly Total'];
export const trackedCategories = [
  'Creation',
  'Generic Task',
  'Maintenance',
  'Outlining',
  'Quality Review',
  'Triage (Backup)',
  'Test Review',
  'No Category',
  'Total',
];

export function parseTrackedEvents(events) {
  const allDailyBreakdowns = {};
  const totalBreakdown = {};

  // calculate daily breakdowns
  for (const [date, dailyEvents] of Object.entries(events)) {
    let trackedEventsByCustomer = _.groupBy(
      dailyEvents.filter((x) => x.type === 'Tracked'),
      'customer',
    );

    const trackerObj = {};

    for (const [customer, eventsByCategory] of Object.entries(trackedEventsByCustomer)) {
      for (const event of eventsByCategory) {
        const category = event.timeTrackingCategory || 'No Category';
        const durationInHours = event.durationInMinutes / 60;

        const data = {
          Creation: 0,
          'Generic Task': 0,
          Maintenance: 0,
          Outlining: 0,
          'Quality Review': 0,
          'Triage (Backup)': 0,
          'Test Review': 0,
          'No Category': 0,
          '': 0,
        };
        data[category] = durationInHours;

        if (!trackerObj[customer]) {
          trackerObj[customer] = data;
        } else {
          if (!trackerObj[customer][category]) {
            trackerObj[customer][category] = durationInHours;
          } else {
            trackerObj[customer][category] += durationInHours;
          }
        }
      }
    }

    trackerObj[''] = {};

    const categoryTotal = {};
    for (const [customer, customerEvents] of Object.entries(trackerObj)) {
      let customerTotal = 0;
      for (const [category, hours] of Object.entries(customerEvents)) {
        if (!categoryTotal[category]) {
          categoryTotal[category] = hours;
        } else {
          categoryTotal[category] += hours;
        }

        customerTotal += hours;
        let displayValue = hours.toFixed(2);
        trackerObj[customer][category] = displayValue === '0.00' ? '-' : displayValue;
      }

      if (customer !== '') {
        trackerObj[customer]['Total'] = customerTotal.toFixed(2);
      }
    }

    let totalOfTotals = 0;
    for (const [key, value] of Object.entries(categoryTotal)) {
      totalOfTotals += value;
      let displayValue = value.toFixed(2);
      categoryTotal[key] = displayValue === '0.00' ? '-' : displayValue;
    }

    categoryTotal['Total'] = totalOfTotals.toFixed(2);
    trackerObj['Total'] = categoryTotal;

    allDailyBreakdowns[date] = trackerObj;
  }

  // calculate total breakdown
  for (const dailyBreakdown of Object.values(allDailyBreakdowns)) {
    for (const [customerOrTotal, data] of Object.entries(dailyBreakdown)) {
      if (!totalBreakdown[customerOrTotal]) {
        totalBreakdown[customerOrTotal] = _.cloneDeep(data);
      } else {
        for (const [category, hours] of Object.entries(data)) {
          if (!totalBreakdown[customerOrTotal][category]) {
            totalBreakdown[customerOrTotal][category] = hours;
          } else {
            if (hours === '-' || hours === 0) {
              continue;
            } else if (totalBreakdown[customerOrTotal][category] === '-') {
              totalBreakdown[customerOrTotal][category] = hours;
            } else {
              const oldTotal = parseFloat(totalBreakdown[customerOrTotal][category]);
              const newTotal = parseFloat(hours);
              totalBreakdown[customerOrTotal][category] = (oldTotal + newTotal).toFixed(2);
            }
          }
        }
      }
    }
  }

  return { allDailyBreakdowns, totalBreakdown };
}

/**
 * Parses events into daily totals and batch totals
 * @param {object} events
 * @param {string} [qaeId]
 * @returns {{allDailyTotals: object, batchTotals: object}}
 */
export function parseEvents(events, qaeId) {
  const allDailyTotals = {};
  const batchTotals = {};

  // Account for Weekend Warriors
  const weekendWarriorsQawIds = [
    'clyn058op08pitrtzuvlhsq4u', // Ian Schaefer: Friday: 11-9 , Saturday: 9-7, Sunday 12-10, Monday 7-5 all EST time
    'clvl02cvt05xhp5vk3qjfntnl', // Mohammad Rizwan: Friday: 2-12 , Saturday: 9-7, Sunday 12-10, Monday 7-5 all EST time
  ];
  const isWeekendWarrior = weekendWarriorsQawIds.includes(qaeId);

  // calculate daily totals
  for (const date of Object.keys(events)) {
    // init tracker
    const trackerObj = {
      tracked: 0,
      ooo: 0,
      triage: 0,
      other: 0,
      overtime: 0,
      untracked: 0,
    };

    // group events by their type
    const eventsByType = _.groupBy(events[date], 'type');

    // sum the total time spent per event type and add to the tracker
    for (const [type, events] of Object.entries(eventsByType)) {
      const sumTimeOfType = _.sumBy(events, (event) => parseInt(event.durationInMinutes)) / 60;

      trackerObj[type.toLowerCase()] += _.round(sumTimeOfType, 2);
    }

    // add total hours of tracked, ooo, other
    trackerObj.total = _.round(_.sum(Object.values(trackerObj)), 2);

    // calculate overtime & untracked time
    const expectedHours = isWeekendWarrior ? 10 : 8;
    let untracked = expectedHours - trackerObj.total;
    let overtime = 0;
    if (untracked < 0) {
      overtime = untracked * -1;
      untracked = 0;
    }

    // handle untracked time for weekend warriors
    if (isWeekendWarrior && [2, 3, 4].includes(dayjs(date).day())) {
      untracked = 0;
      overtime += trackerObj.total;
      overtime -= trackerObj.untracked;
    }

    trackerObj.overtime = _.round(overtime, 2);
    trackerObj.untracked = _.round(untracked, 2);

    allDailyTotals[date] = trackerObj;
  }

  // calculate batch totals
  for (const dailyTotals of Object.values(allDailyTotals)) {
    for (const [type, hours] of Object.entries(dailyTotals)) {
      if (!batchTotals[type]) {
        batchTotals[type] = hours;
      } else {
        batchTotals[type] = _.round(batchTotals[type] + hours, 2);
      }
    }
  }

  return { allDailyTotals, batchTotals };
}

export function groupAndSortEvents(events) {
  // group events by date
  const eventsByDateUnsorted = _.groupBy(events, 'date');

  // sort by date desc
  const eventsByDateSorted = _.fromPairs(_.sortBy(_.toPairs(eventsByDateUnsorted), (entry) => -new Date(entry[0])));

  return eventsByDateSorted;
}

/**
 *  Normalizes events for chart
 * @param {*} events
 * @returns {Array<{name: string, value: number, fill: string}>} normalizedEvents
 */
export function normalizeEventsForChart(events) {
  const normalizedEvents = [];
  const normalizedEventsMap = {
    // tracked: { name: 'Tracked', value: 0, fill: '#1f77b4' },
    ooo: { name: 'OOO', value: 0, fill: '#ff7f0e' },
    triage: { name: 'Triage', value: 0, fill: '#2ca02c' },
    other: { name: 'Meetings/Other', value: 0, fill: '#8c564b' },
    overtime: { name: 'Overtime', value: 0, fill: '#9467bd' },
    untracked: { name: 'Untracked', value: 0, fill: '#d62728' },
  };

  // increment the value of each category in normalizedTeamEventsMap by the duration of each event
  for (const qaeEvents of Object.values(events)) {
    for (const [type, hours] of Object.entries(qaeEvents)) {
      // don't include 'Total' or 'Tracked' in the normalized events. 'Total' is handled separately.
      if (type !== 'total' && type !== 'tracked' && normalizedEventsMap[type]) {
        normalizedEventsMap[type].value += hours;
      }
    }
  }

  // add to normalizedTeamEvents
  for (const category of Object.keys(normalizedEventsMap)) {
    normalizedEventsMap[category].value = _.round(normalizedEventsMap[category].value, 2);
    normalizedEvents.push(normalizedEventsMap[category]);
  }

  return normalizedEvents;
}

/**
 * Normalizes tracked events for chart
 * @param {*} events
 * @returns {Array<{name: string, value: number, fill: string}>} normalizedEvents
 */
export function normalizeTrackedEventsForChart(events) {
  const normalizedEvents = [];
  const normalizedEventsMap = {
    Creation: { name: 'Creation', value: 0, fill: '#1f77b4' },
    'Generic Task': { name: 'Generic Task', value: 0, fill: '#e377c2' },
    Maintenance: { name: 'Maintenance', value: 0, fill: '#17becf' },
    Outlining: { name: 'Outlining', value: 0, fill: '#bcbd22' },
    'Quality Review': { name: 'Quality Review', value: 0, fill: '#7f7f7f' },
    'Triage (Backup)': { name: 'Triage (Backup)', value: 0, fill: '#aec7e8' },
    'Test Review': { name: 'Test Review', value: 0, fill: '#ffbb78' },
    'No Category': { name: 'No Category', value: 0, fill: '#98df8a' },
  };

  // increment the value of each category in normalizedTeamEventsMap by the duration of each event
  for (const qaeEvents of Object.values(events)) {
    if (qaeEvents['Total']) {
      for (const [category, hours] of Object.entries(qaeEvents['Total'])) {
        if (normalizedEventsMap[category] && hours !== '-') {
          normalizedEventsMap[category].value += parseFloat(hours);
        }
      }
    }
  }

  // add to normalizedTeamEvents
  for (const category of Object.keys(normalizedEventsMap)) {
    normalizedEventsMap[category].value = _.round(normalizedEventsMap[category].value, 2);
    normalizedEvents.push(normalizedEventsMap[category]);
  }

  return normalizedEvents;
}

/**
 * Returns the week number of a given date
 * @param {Date} date
 * @returns {number} weekNumber
 */
export function getWeekNumber(date) {
  const weekNumber = dayjs(date).week();
  return weekNumber;
}

/**
 * Groups parsed events by week
 * @param {object} totals
 * @returns {object} totalsWithData
 */
export function getTotalsByWeek(totals) {
  const weeklyTotalsGrouped = _.groupBy(Object.entries(totals), ([date]) => {
    return getWeekNumber(new Date(date));
  });

  const totalsWithData = _.mapValues(weeklyTotalsGrouped, (weeks) => {
    const weekData = _.fromPairs(weeks);

    const totals = Object.values(weekData).reduce((acc, day) => {
      for (const [category, hours] of Object.entries(day)) {
        if (!acc[category]) {
          acc[category] = 0;
        }
        acc[category] += hours;
      }
      return acc;
    }, {});

    weekData.totals = totals;
    return weekData;
  });

  const sortedTotals = new Map(_.orderBy(Object.entries(totalsWithData), ([weekNumber]) => parseInt(weekNumber, 10), ['desc']));

  return sortedTotals;
}

export function getBreakdownsByWeek(breakdowns) {
  const weeklyBreakdownsGrouped = _.groupBy(Object.entries(breakdowns), ([date]) => {
    return getWeekNumber(new Date(date));
  });

  const breakdownsWithData = _.mapValues(weeklyBreakdownsGrouped, (weeks) => {
    const weekData = _.fromPairs(weeks);

    return weekData;
  });

  return breakdownsWithData;
}
