import { sendGetRequest } from '../../utils/network';
import dayjs from 'dayjs';

export async function checkAuthentication() {
  const tokenExpiration = localStorage.getItem('tokenExpiration');

  // /checkLogin returns true if the token is still valid
  const checkLoginResponse = await sendGetRequest(`/checklogin`);
  const isTokenStale = !checkLoginResponse?.data?.logged_in;

  // Return true if token is within 1 minutes of expiration
  const tokenNearingExpiration = tokenExpiration ? dayjs.utc().isAfter(dayjs.utc(tokenExpiration).subtract(1, 'minute')) : true;

  // Authentication does not pass if token is invalid or we are within 1 minutes of expiration
  if (isTokenStale || tokenNearingExpiration) {
    return false;
  } else {
    localStorage.removeItem('tokenExpired');
    return true;
  }
}
