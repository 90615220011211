import { useCallback, useEffect } from 'react';

import LibraryAddTwoToneIcon from '@mui/icons-material/LibraryAddTwoTone';
import IndeterminateCheckBoxTwoToneIcon from '@mui/icons-material/IndeterminateCheckBoxTwoTone';

import { classNames } from '../../InvestigationBoard/helpers';

/**
 * Base card for metrics playground legend
 * @param {object} props
 * @param {import('../types').ActiveCard} props.card
 * @param {import('../types').CustomLegendToggle[]} props.toggles
 * @param {{[key: string]: boolean}} props.visibilityProps
 * @param {import('react').Dispatch<import('react').SetStateAction<{[key: string]: boolean}>>} props.visibilitySetter
 * @param {{hover: string | null}} props.hoverProps
 * @param {import('react').Dispatch<import('react').SetStateAction<{hover: string | null}>>} props.hoverSetter
 * @returns {import('react').JSX.Element}
 */
export default function CardBase({ card, toggles, visibilityProps, visibilitySetter, hoverProps, hoverSetter }) {
  // handle hover states for legend toggles
  const handleMouseEnter = useCallback((toggle) => {
    if (!hoverProps[toggle.identifier]) {
      hoverSetter((prev) => ({ ...prev, hover: toggle.identifier }));
    }
  }, []);

  const handleMouseLeave = useCallback(() => {
    hoverSetter((prev) => ({ ...prev, hover: null }));
  }, []);

  // handle click actions for legend toggles
  const handleToggleClick = useCallback((toggle) => {
    // set new visibility state and handle side effects
    visibilitySetter((prevState) => {
      const newVisibilityProps = {
        ...prevState,
        [toggle.identifier]: !prevState[toggle.identifier],
      };

      // remove hover state
      hoverSetter({ hover: null });

      return newVisibilityProps;
    });
  }, []);

  // handle bulk toggle click
  const handleToggleBulkClick = useCallback(
    /** @param {import('../types').CustomLegendToggle} toggle */
    (toggle) => {
      // set new visibility state and handle side effects
      visibilitySetter((prevState) => {
        // handle same card type toggling
        const newVisibilityProps = {
          ...prevState,
          [`ALL:${toggle.value}`]: !prevState[`ALL:${toggle.value}`],
        };

        // handle cross card type toggling
        if (toggle.type === 'qae') {
          newVisibilityProps[`ALL:Aggregated Average ${toggle.value}`] = !prevState[`ALL:Aggregated Average ${toggle.value}`];
        } else if (toggle.value.includes('Average')) {
          // modify value string to remove Aggregated Average prefix
          const strippedValue = toggle.value.replace('Aggregated Average ', '');
          newVisibilityProps[`ALL:${strippedValue}`] = !prevState[`ALL:${strippedValue}`];
        }

        return newVisibilityProps;
      });
    },
    [],
  );

  useEffect(() => {
    // set initial visibility state for all toggles of parent cards
    visibilitySetter((prevState) => {
      const newVisibilityProps = { ...prevState };

      // if user has already manually toggled any lines, or provided some lines in the search params, don't override
      if (Object.entries(newVisibilityProps).some(([key, value]) => !key.includes('Average Impact Score') && value === true)) {
        return newVisibilityProps;
      }

      // else set visibility state for all toggles of parent cards
      toggles.forEach((toggle) => {
        newVisibilityProps[toggle.identifier] = toggle.identifier.includes('Average Impact Score');
      });
      return newVisibilityProps;
    });
  }, []);

  return (
    <div className="flex flex-col shadow p-2 min-w-max max-w-max">
      <p className="flex items-center gap-x-4">
        <img className="rounded-full h-10 w-auto" src={card.imageUrl} alt="avatar" />
        <span className="font-semibold">{card.cardName}</span>
      </p>
      <ul>
        {toggles.length &&
          toggles.map((toggle) => {
            return (
              <li key={toggle.identifier} className="flex justify-between gap-x-2 my-1 truncate">
                <button
                  className={classNames('flex items-center', !visibilityProps[toggle.identifier] && 'text-gray-400')}
                  onClick={() => handleToggleClick(toggle)}
                  onMouseEnter={() => handleMouseEnter(toggle)}
                  onMouseLeave={handleMouseLeave}
                >
                  <span
                    className="h-3.5 w-3.5 mr-2 rounded-full"
                    style={{
                      backgroundColor:
                        visibilityProps[toggle.identifier] ||
                        (visibilityProps[`ALL:${toggle.identifier.split(':').at(-1)}`] && card.cardSetId !== 'ORG_CARD')
                          ? toggle.color
                          : '#ccc',
                    }}
                  />
                  <span className="truncate">{toggle.value}</span>
                </button>
                {card.cardSetId !== 'ORG_CARD' ? (
                  <button
                    className={classNames(!visibilityProps[`ALL:${toggle.identifier.split(':').at(-1)}`] && 'text-gray-400')}
                    onClick={() => handleToggleBulkClick(toggle)}
                  >
                    {!visibilityProps[`ALL:${toggle.identifier.split(':').at(-1)}`] ? (
                      <LibraryAddTwoToneIcon />
                    ) : (
                      <IndeterminateCheckBoxTwoToneIcon />
                    )}
                  </button>
                ) : (
                  <div className="w-6 spacer"></div>
                )}
              </li>
            );
          })}
      </ul>
    </div>
  );
}
