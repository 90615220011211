import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, Stack } from '@mui/material';
import { useMemo } from 'react';
import { max, min } from 'lodash';

export default function TeamOverviewTable({ tableData }) {
  
  const columns = useMemo(() => {
    return [
      {
        header: 'Team Name',
        muiTableHeadCellProps: { title: "Team Name" },
        accessorKey: 'teamName',
        filterVariant: 'multi-select',
      },
      {
        header: 'Pack Manager',
        muiTableHeadCellProps: { title: "Pack Manager" },
        accessorKey: 'packManager.name',
        filterVariant: 'multi-select',
      },
      {
        header: 'Customer ARR',
        muiTableHeadCellProps: { title: "Total sum of Team's Customer ARR" },
        accessorKey: 'teamArr',
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: max(tableData.map((team) => team.teamArr)),
          step: 10_000,
          valueLabelFormat: (value) =>
            value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0,
            }),
        },
        Cell: ({ cell }) => cell.getValue()
          .toLocaleString('en-US', {
            style: 'currency', 
            currency: 'USD',
            maximumFractionDigits: 0,
          },
        ),
        Footer: ({ table }) => {
          const filteredTotal = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamArr, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {(filteredTotal / filteredRowCount).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: 'Costs',
        muiTableHeadCellProps: {
          title: "Total sum of Hosting Costs (divdied between all teams) and Salaries (plus benefits) of Team's QAEs, Team's Lead, Team's Manager (divided between all teams under manager) and Director of Engineering (divided between all teams)",
        },
        accessorKey: 'teamCost',
        Cell: ({ cell }) => cell.getValue()
          .toLocaleString('en-US', {
            style: 'currency', 
            currency: 'USD',
            maximumFractionDigits: 0,
          },
        ),
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: max(tableData.map((team) => team.teamCost)),
          step: 10_000,
          valueLabelFormat: (value) =>
            value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0,
            }),
        },
        Footer: ({ table }) => {
          const filteredTotal = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamCost, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {(filteredTotal / filteredRowCount).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: 'Margin',
        muiTableHeadCellProps: { title: "Difference between Team's ARR and Team's Costs" },
        accessorKey: 'teamMargin',
        Cell: ({ cell }) => cell.getValue()
          .toLocaleString('en-US', {
            style: 'currency', 
            currency: 'USD',
            maximumFractionDigits: 0,
          },
        ),
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: max(tableData.map((team) => team.teamMargin)),
          step: 10_000,
          valueLabelFormat: (value) =>
            value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0,
            }),
        },
        Footer: ({ table }) => {
          const filteredMargin = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamMargin, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
              {(filteredMargin / filteredRowCount).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: '% Margin',
        muiTableHeadCellProps: { title: "Percentage difference between Team's ARR and Team's Costs" },
        id: 'teamMarginPercentage',
        accessorFn: (row) => Math.round((row.teamMargin / row.teamArr) * 100) + "%",
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: min(tableData.map((team) => (team.teamMargin / team.teamArr) * 100)),
          max: max(tableData.map((team) => (team.teamMargin / team.teamArr) * 100)),
          step: 1,
          valueLabelFormat: (value) => value.toLocaleString('en-US', { maximumFractionDigits: 0 }) + '%',
        },
        Footer: ({ table }) => {
          const filteredMarginPercentage = table.getFilteredRowModel().rows.reduce((acc, row) => {
            const marginPercentage = Math.round((row.original.teamMargin / row.original.teamArr) * 100);
            return acc + marginPercentage;
          }, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
              </Box>              
              {Math.round(filteredMarginPercentage / filteredRowCount) + "%"}
            </Stack>
          );
        },
      },
      {
        header: 'Tests Under Contract',
        muiTableHeadCellProps: { title: "Amount of Tests Contracted with Team's Customers" },
        accessorKey: 'teamTestsUnderContract',
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: max(tableData.map((team) => team.teamTestsUnderContract)),
          step: 100,
          valueLabelFormat: (value) => value.toLocaleString('en-US', { maximumFractionDigits: 0 }),
        },
        Footer: ({ table }) => {
          const filteredTotal = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamTestsUnderContract, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {(filteredTotal / filteredRowCount).toLocaleString()}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: 'Active Tests',
        muiTableHeadCellProps: { title: "Amount of Team's Tests that have been implemented, includes Bugged and Maintenance" },
        id: 'teamActiveTests',
        accessorFn: (row) => row.teamActiveTests,
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: max(tableData.map((team) => team.teamActiveTests)),
          step: 100,
          valueLabelFormat: (value) => value.toLocaleString('en-US', { maximumFractionDigits: 0 }),
        },
        Footer: ({ table }) => {
          const filteredTotalActive = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamActiveTests, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {(filteredTotalActive / filteredRowCount).toLocaleString('en-US', { maximumFractionDigits: 0 })}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: '% Tests in Maintenance',
        muiTableHeadCellProps: { title: "Percentage of Team's Active Tests in Maintenance" },
        id: 'teamTestsInMaintenance',
        accessorFn: (row) => Math.round((row.teamTestsInMaintenance / row.teamActiveTests) * 100) + "%",
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: 100,
          step: 5,
          valueLabelFormat: (value) => value.toLocaleString('en-US', { maximumFractionDigits: 0 }) + '%',
        },
        Footer: ({ table }) => {
          const filteredMaintenancePercentage = table.getFilteredRowModel().rows.reduce((acc, row) => {
            const maintenancePercentage = Math.round((row.original.teamTestsInMaintenance / row.original.teamActiveTests) * 100);
            return acc + maintenancePercentage;
          }, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {Math.round(filteredMaintenancePercentage / filteredRowCount) + "%"}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: '% Tests Bugged',
        muiTableHeadCellProps: { title: "Percentage of Team's Active Tests that are Bugged" },
        id: 'teamTestsBugged',
        accessorFn: (row) => Math.round((row.teamTestsBugged / row.teamActiveTests) * 100) + "%",
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: 100,
          step: 5,
          valueLabelFormat: (value) => value.toLocaleString('en-US', { maximumFractionDigits: 0 }) + '%',
        },
        Footer: ({ table }) => {
          const filteredBuggedPercentage = table.getFilteredRowModel().rows.reduce((acc, row) => {
            const buggedPercentage = Math.round((row.original.teamTestsBugged / row.original.teamActiveTests) * 100);
            return acc + buggedPercentage;
          }, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {Math.round(filteredBuggedPercentage / filteredRowCount) + "%"}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: 'QA Team Size (Incl. Lead)',
        muiTableHeadCellProps: { title: "Amount of Engineers on Team (Including Leads)" },
        accessorKey: 'teamSize',
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: max(tableData.map((team) => team.teamSize)),
          step: 1,
          valueLabelFormat: (value) => value.toLocaleString('en-US', { maximumFractionDigits: 0 }),
        },
        Footer: ({ table }) => {
          const filteredTotal = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamSize, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {Math.round(filteredTotal / filteredRowCount)}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: 'Avg. Active Tests per QAE',
        muiTableHeadCellProps: { title: "Team's Active Tests divided by amount of QAEs on Team (Excluding Leads)" },
        id: 'teamAverageActiveTestsPerQae',
        accessorFn: (row) => row.teamAverageActiveTestsPerQae,
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: max(tableData.map((team) => team.teamAverageActiveTestsPerQae)),
          step: 5,
          valueLabelFormat: (value) => value.toLocaleString('en-US', { maximumFractionDigits: 0 }),
        },
        Footer: ({ table }) => {
          const filteredTotal = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamAverageActiveTestsPerQae, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {Math.round((filteredTotal / filteredRowCount))}
              </Box>
            </Stack>
          );
        },
      },
    ];
  }, [tableData]);

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
    },
    columnFilterDisplayMode: 'popover',
    enableColumnActions: true,
    enableColumnFilters: true,
    enableTopToolbar: true,
    enableTableFooter: true,
    enableFacetedValues: true,
  });

  return <MaterialReactTable table={table} />;
}
