import {
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useQuery } from '@tanstack/react-query';
import getColumns from './columns';
import { getFilterValues } from '../Tasks/api';
import Toast from '../Layout/Toast';
import { Box, Button, FormControlLabel, IconButton, Menu, MenuItem, Switch, Avatar, Tooltip } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import { Settings, TransferWithinAStationOutlined, Timeline, SwapVertOutlined, CalendarTodayOutlined } from '@mui/icons-material';
import BlockedReasonModal from './BlockedReasonModal';
import { deepEqual, getSelectedRowData } from '../Tasks/helpers';
import { useLocation } from 'react-router-dom';
import { sendGetRequest, sendPostRequest } from '../../utils/network';
import dayjs from 'dayjs';

export default function TasksTable({
  data,
  initialInstanceState,
  setToast,
  showSkeletons,
  showProgressBars,
  enableSavingViews = false,
  tableState,
  setTableState,
  saveViewButton,
  clearSelectedView,
  selectedView,
  filterCriteria = [],
  defaultTableState,
  queryClient,
}) {
  const [filterFromLeafRowsEnabled /* , setFilterFromLeafRowsEnabled */] = useState(false);
  const [columnReorderingEnabled, setColumnReorderingEnabled] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAssignee, setAnchorElAssignee] = useState(null);
  const [anchorElDate, setAnchorElDate] = useState(null);
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const [anchorElPriority, setAnchorElPriority] = useState(null);
  const [showBlockedReasonModal, setShowBlockedReasonModal] = useState(false);
  const [originalBlockedTask, setOriginalBlockedTask] = useState(null);

  const { data: filterValues } = useQuery({
    queryKey: ['filterValues'],
    queryFn: getFilterValues,
    placeholderData: { users: [], statuses: [], priorities: [] },
  });

  // Check for customerId/filterForMissingBlockerReasons boolean
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const customerId = searchParams.get('customerId');
  const showBlockerTasks = searchParams.get('filterForMissingBlockerReasons') === 'true';

  // Get blocked or partially blocked crs that have not had a reason added through the modal
  const { data: blockedCrsWithoutReasons } = useQuery({
    queryKey: ['blockedCrsWithoutReasons', customerId],
    queryFn: async () => {
      if (!customerId || !showBlockerTasks) return [];
      const res = await sendGetRequest(`/${customerId}/crs-without-blocker-reason`);
      return res.data;
    },
    placeholderData: [],
  });

  const columns = getColumns({ filterValues, setToast, setShowBlockedReasonModal, setOriginalBlockedTask, tableState });

  const handleGroupCRandMRsChange = (bool) => {
    setTableState((tableState) => ({
      ...tableState,
      groupCRandMRs: bool,
      rowSelection: {},
    }));
  };

  const bulkUpdateTasks = async (column, updateValue) => {
    const rowSelection = tableState.rowSelection;
    const currentData = [...data];
    const bulkTaskData = getSelectedRowData(currentData, rowSelection);

    let taskData = {
      tasks: bulkTaskData,
      dueDate: column === 'dueDate' ? updateValue : undefined,
      status: column === 'status' ? updateValue : undefined,
      assigneeId: column === 'assignee' ? updateValue : undefined,
      priority: column === 'priority' ? updateValue : undefined,
    };

    const { success, data: returnedData, error } = await sendPostRequest(`/update-tasks`, taskData);

    if (!success || error) {
      console.log(returnedData);
      setToast(<Toast title={'Ruh roh'} message={`${error}`} key={new Date().toISOString()} isSuccess={true} />);
    } else {
      setToast(<Toast title={'Success!'} message={'Successfully updated the tasks.'} key={new Date().toISOString()} isSuccess={true} />);
      queryClient.refetchQueries({
        queryKey: ['allTasks'],
      });
    }
  };

  //pass table options to useMaterialReactTable
  const table = useMaterialReactTable({
    // @ts-ignore
    columns,
    data: showBlockerTasks ? blockedCrsWithoutReasons : data,
    state: {
      showGlobalFilter: true,
      showAlertBanner: Object.entries(tableState.rowSelection).length > 0,
      columnPinning: { left: ['mrt-row-expand', 'mrt-row-select'] },
      showSkeletons: showSkeletons,
      showProgressBars: showProgressBars,
      ...tableState,
    },
    onStateChange: (newState) => {
      if (typeof newState === 'function') {
        // Apply the updater function to the current state to get the new state
        setTableState((prevState) => {
          const updatedState = newState(prevState);
          return updatedState;
        });
      } else {
        setTableState(newState);
      }
    },
    onColumnOrderChange: (newOrder) => {
      // Update the tableState with the new column order
      setTableState((prevState) => ({
        ...prevState,
        columnOrder: newOrder,
      }));
    },
    onPaginationChange: (updater) => {
      setTableState((oldState) => {
        // Functional update: Get the new pagination state based on the old state and the updater function
        let newState = {
          ...oldState,
          pagination: typeof updater === 'function' ? updater(oldState.pagination) : updater,
        };
        return newState;
      });
    },
    onColumnFilterFnsChange: (updater) => {
      // Update the tableState with the new columnFilterFn state
      setTableState((oldState) => {
        let newState = {
          ...oldState,
          columnFilterFns: typeof updater === 'function' ? updater(oldState.columnFilterFns) : updater,
        };
        return newState;
      });
    },
    onGlobalFilterFnChange: (updater) => {
      // Update the tableState with the new globalFilterFn state
      setTableState((oldState) => {
        let newState = {
          ...oldState,
          globalFilterFn: typeof updater === 'function' ? updater(oldState.globalFilterFns) : updater,
        };
        return newState;
      });
    },
    initialState: {
      density: 'compact',
      ...initialInstanceState,
    },

    /* view options */
    layoutMode: 'grid',
    editDisplayMode: 'cell',
    // enableStickyHeader: true, // TODO: reconsider
    // enableStickyFooter: true,
    enableExpanding: tableState.groupCRandMRs,
    defaultColumn: { size: 150, grow: false },

    /* pagination options */
    paginateExpandedRows: false,
    autoResetPageIndex: false,

    /* grouping options */
    enableGrouping: false, // could turn this on to allow custom grouping
    groupedColumnMode: false,

    /* sorting options */
    isMultiSortEvent: () => true, //multi-sorting will be the default click behavior without the need to hold shift

    /* select options */
    enableRowSelection: true,
    enableSubRowSelection: false,
    positionToolbarAlertBanner: 'head-overlay',
    selectAllMode: 'all',

    // global filtering options
    enableGlobalFilter: true,
    enableGlobalFilterModes: true,
    globalFilterModeOptions: ['startsWith', 'contains', 'fuzzy'],
    positionGlobalFilter: 'left',
    muiSearchTextFieldProps: ({ table }) => ({
      placeholder: `Search ${table.getPrePaginationRowModel().rows.length} rows`,
      sx: { minWidth: '500px', boxShadow: 'none', '& .MuiOutlinedInput-input': { boxShadow: 'none' } },
      variant: 'outlined',
    }),

    /* column filtering options */
    enableFacetedValues: true,
    filterFromLeafRows: filterFromLeafRowsEnabled, // can use a toggle to change this
    maxLeafRowFilterDepth: 0,
    columnFilterDisplayMode: 'popover',
    enableColumnFilterModes: true,
    muiFilterTextFieldProps: {
      sx: { m: '0.5rem 0', width: '100%', '.MuiOutlinedInput-input': { boxShadow: 'none' } },
      inputProps: { sx: { boxShadow: 'none!important' } },
      variant: 'standard',
      size: 'small',
    },

    /* pinning options */
    enableRowPinning: false,
    enableColumnPinning: true,

    /* editing options */
    enableEditing: true,
    enableColumnOrdering: columnReorderingEnabled,
    enableColumnResizing: true,

    /* defines where subRows are located */
    getSubRows: (originalRow) => originalRow.childTasks,
    getRowId: (originalRow) => originalRow.id,

    /* turns off loading bar on bottom toolbar */
    muiLinearProgressProps: ({ isTopToolbar }) => ({
      sx: {
        display: isTopToolbar ? 'block' : 'none',
      },
    }),

    /* enable single click cell editing */
    muiTableBodyCellProps: ({ cell, column, table }) => ({
      onClick: () => {
        table.setEditingCell(cell);
        //optionally, focus the text field
        queueMicrotask(() => {
          const textField = table.refs.editInputRefs.current[column.id];
          if (textField && cell.row.original.type !== 'testCoverageRequest') {
            textField.focus();
            textField.select?.();
          }
        });
      },
    }),

    /* gloabl settings for cell editing */
    muiEditTextFieldProps: () => ({
      SelectProps: { defaultOpen: true, open: true },

      // error: !!validationErrors?.state,
      // helperText: validationErrors?.state,
      // onBlur: (event) => {
      //   console.log(row.original.id, event);
      //   // handleSaveCell(cell, event.target.value);
      // },
      // onChange: (event) => console.log(row.original.id, event),

      // setEditedUsers({
      //   ...editedUsers,
      //   [row.id]: { ...row.original, state: event.target.value },
      // }),
    }),

    /* tweaks to the expand/collapse chevron to align right/down as expected */
    muiExpandButtonProps: ({ row }) => {
      const expanded = row.getIsExpanded();
      if (!expanded && row.subRows?.length) return { style: { transform: 'rotate(-90deg)' } };
      if (expanded) return { style: { transform: 'rotate(-180deg)' } };
    },

    muiExpandAllButtonProps: ({ table }) => {
      const someExpanded = table.getIsSomeRowsExpanded();
      const allExpanded = table.getIsAllRowsExpanded();
      if (!someExpanded) return { style: { transform: 'rotate(-90deg)' } };
      if (allExpanded) return { style: { transform: 'rotate(-180deg)' } };
    },

    /* limit size of select and expand columns */
    displayColumnDefOptions: {
      'mrt-row-expand': {
        size: 10,
        grow: false,
      },
      'mrt-row-select': {
        size: 10,
        grow: false,
      },
    },

    /* select banner (displayed when 1 or more rows selected) */
    renderToolbarAlertBannerContent: ({ table }) => {
      let selectedRowModel = table.getSelectedRowModel();
      let prePaginationRowModel = table.getPrePaginationRowModel();

      let rowSelectionCount = Object.entries(tableState.rowSelection).length;
      let parentRowSelectionCount = selectedRowModel.rows.length;
      let parentRowCount = prePaginationRowModel.rows.length;
      let childRowSelectionCount = selectedRowModel.flatRows.length - parentRowSelectionCount;
      let childRowCount = prePaginationRowModel.flatRows.length - parentRowCount;

      let parentBreakdown = parentRowSelectionCount ? `${parentRowSelectionCount}/${parentRowCount} Parent` : '';
      let childBreakdown = childRowSelectionCount ? `${childRowSelectionCount}/${childRowCount} Child` : '';

      // Predefined row selection summary based on grouped or ungrouped data
      let ungroupedText = `${rowSelectionCount} of ${prePaginationRowModel.rows.length} row(s) selected.`;
      let groupedText = `${parentBreakdown}${parentRowSelectionCount && childRowSelectionCount ? ' and ' : ''}${childBreakdown} row(s) selected`;

      // Boolean to disable bulk status updates when a testCoverageRequest task is selected
      let statusDisabled = tableState.groupCRandMRs && selectedRowModel.rows.some((row) => row.original.type === 'testCoverageRequest');

      // If any row (child or parent) is selected, display the toolbar
      if (rowSelectionCount > 0) {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: '6px',
                p: '4px 12px',
                width: '100%',
              }}
            >
              {tableState.groupCRandMRs ? groupedText : ungroupedText}
              <Button color="error" size="medium" variant="text" onClick={() => table.resetRowSelection()}>
                Clear selection
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '6px',
              }}
            >
              {/* Assignee - menu to bulk update taks */}
              <Tooltip title="Edit Assignee">
                <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => setAnchorElAssignee(e.currentTarget)}>
                  <TransferWithinAStationOutlined sx={{ color: 'secondary' }} />
                </IconButton>
              </Tooltip>
              <Menu
                id="assignee-bulk-menu"
                anchorEl={anchorElAssignee}
                keepMounted
                open={Boolean(anchorElAssignee)}
                onClose={() => setAnchorElAssignee(null)}
              >
                {filterValues.users.map((user) => {
                  return (
                    <MenuItem
                      key={user.name}
                      onClick={() => {
                        // Update the tasks assignee ID in DB and cache
                        bulkUpdateTasks('assignee', user.qawId);
                        // Close menu
                        setAnchorElAssignee(null);
                        // Reset rows selected if the current assignee filter doesn't include the selected user
                        let assigneeFitler = tableState.columnFilters.find((filter) => filter.id === 'assigneeName');
                        if (assigneeFitler && !assigneeFitler?.value.includes(user.name)) {
                          table.resetRowSelection();
                        }
                      }}
                    >
                      <Avatar src={user.avatar48} alt={user.name} style={{ width: 24, height: 24, marginRight: 8 }} />
                      {user.name}
                    </MenuItem>
                  );
                })}
              </Menu>

              {/* Due Date - menu to bulk update taks */}
              <Tooltip title="Edit Due Date">
                <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => setAnchorElDate(e.currentTarget)}>
                  <CalendarTodayOutlined sx={{ color: 'secondary' }} />
                </IconButton>
              </Tooltip>
              <Menu id="date-bulk-menu" anchorEl={anchorElDate} keepMounted open={Boolean(anchorElDate)} onClose={() => setAnchorElDate(null)}>
                <DateCalendar
                  views={['month', 'day']}
                  minDate={dayjs().subtract(1, 'month')}
                  maxDate={dayjs().add(3, 'month')}
                  showDaysOutsideCurrentMonth={true}
                  timezone="system" // Get timezone from system
                  onChange={(newDate, selectionState) => {
                    // Only submit when a full date is picked
                    if (selectionState === 'finish') {
                      // Update the tasks due dates in DB and cache
                      const date = dayjs(newDate).format('YYYY-MM-DD');
                      bulkUpdateTasks('dueDate', date);
                      // Close menu
                      setAnchorElDate(null);
                      // Reset rows selected if the current date range doesn't include the new date
                      let dateFilters = tableState.columnFilters.find((filter) => filter.id === 'dueDate')?.value;
                      let [startDate, endDate] = dateFilters || ['', ''];
                      if (dateFilters && !newDate.isBetween(startDate, endDate)) {
                        table.resetRowSelection();
                      }
                    }
                  }}
                />
              </Menu>

              {/* Status - menu to bulk update taks */}
              <Tooltip title={statusDisabled ? 'Cannot edit status of Coverage Requests' : 'Edit Status'}>
                <span
                  style={{
                    cursor: statusDisabled ? 'not-allowed' : 'pointer',
                    pointerEvents: statusDisabled ? 'auto' : 'inherit', // Enable pointer events for the cursor
                    display: 'inline-block', // Ensure the span wraps the button correctly
                  }}
                >
                  <IconButton
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    disabled={statusDisabled}
                    onClick={(e) => setAnchorElStatus(e.currentTarget)}
                  >
                    <Timeline sx={{ color: 'secondary' }} />
                  </IconButton>
                </span>
              </Tooltip>
              <Menu
                id="status-bulk-menu"
                anchorEl={anchorElStatus}
                keepMounted
                open={Boolean(anchorElStatus)}
                onClose={() => setAnchorElStatus(null)}
              >
                {filterValues.statuses.map((status) => {
                  if (status.value === 'blocked') return;
                  return (
                    <MenuItem
                      key={status.value}
                      sx={{ backgroundColor: status.color, color: status.textColor }}
                      onClick={() => {
                        // Update the tasks statuses in DB and cache
                        bulkUpdateTasks('status', status.value);
                        // Close menu
                        setAnchorElStatus(null);
                        // Reset rows selected if the current status filter doesn't include the selected user
                        let statusFilter = tableState.columnFilters.find((filter) => filter.id === 'status');
                        if (statusFilter && !statusFilter?.value.includes(status.value)) {
                          table.resetRowSelection();
                        }
                      }}
                    >
                      {status.label}
                    </MenuItem>
                  );
                })}
              </Menu>

              {/* Priority - menu to bulk update taks */}
              <Tooltip title="Edit Priority">
                <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => setAnchorElPriority(e.currentTarget)}>
                  <SwapVertOutlined sx={{ color: 'secondary' }} />
                </IconButton>
              </Tooltip>
              <Menu
                id="priority-bulk-menu"
                anchorEl={anchorElPriority}
                keepMounted
                open={Boolean(anchorElPriority)}
                onClose={() => setAnchorElPriority(null)}
              >
                {filterValues.priorities.map((priority) => {
                  return (
                    <MenuItem
                      key={priority.value}
                      sx={{ backgroundColor: priority.color, color: priority.textColor }}
                      onClick={() => {
                        // Update the tasks priorities in DB and cache
                        bulkUpdateTasks('priority', priority.value);
                        // Close menu
                        setAnchorElPriority(null);
                        // Reset rows selected if the current priority filter doesn't include the selected user
                        let priorityFilter = tableState.columnFilters.find((filter) => filter.id === 'priority');
                        if (priorityFilter && !priorityFilter?.value.includes(priority.value)) {
                          table.resetRowSelection();
                        }
                      }}
                    >
                      {priority.label}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Box>
          </Box>
        );
      }

      return null;
    },

    /* custom actions in middle/left of table toolbar */
    renderTopToolbarCustomActions: ({ table }) => {
      const { reset, getState } = table;

      const { columnFilters, globalFilter, sorting } = getState();

      const resetTable = () => {
        console.log('reset');
        reset(); // Resets column filters
        setTableState && setTableState(defaultTableState); // Resets global filter and sorting
        clearSelectedView && clearSelectedView(0);
      };

      // check if any sorting or filtering is applied
      if (
        // a view other than home is selected
        (selectedView && selectedView > 0) ||
        // or no sorting or filtering is applied outside of Default Filters
        (!Object.values(columnFilters).some((filter) => filter.value) &&
          !globalFilter &&
          !Object.values(sorting).some((sort) => sort) &&
          tableState.groupCRandMRs === false &&
          tableState.pagination.pageSize === defaultTableState.pagination.pageSize &&
          deepEqual(tableState.columnSizing, {}))
      ) {
        return <></>;
      }

      return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', gap: '1rem', p: '4px' }}>
          <Button color="primary" onClick={resetTable} variant="contained">
            Reset Sorting & Filtering
          </Button>
          {enableSavingViews && saveViewButton}
        </Box>
      );
    },

    /* custom actions in right of table toolbar */
    renderToolbarInternalActions: ({ table }) => (
      <Box sx={{ display: 'flex' }}>
        {/* along-side built-in buttons in whatever order you want them */}
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleDensePaddingButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => setAnchorEl(e.currentTarget)}>
          <Settings />
        </IconButton>
        <Menu id="table-settings-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          <MenuItem key="toggle-reorder-columns">
            <FormControlLabel
              control={<Switch checked={columnReorderingEnabled} onChange={() => setColumnReorderingEnabled(!columnReorderingEnabled)} />}
              label="Reorder Columns"
              title={`Allow columns to be reordered by dragging and dropping. Custom column order will persist when toggled off.`}
            />
          </MenuItem>
          <MenuItem key="toggle-testCoverageRequest-task-type">
            <FormControlLabel
              control={<Switch checked={tableState.groupCRandMRs} onChange={() => handleGroupCRandMRsChange(!tableState.groupCRandMRs)} />}
              label="Group Coverage Requests & Maint Reports"
              title={`Show coverage requests and maintenance reports as parent tasks on task board.`}
            />
          </MenuItem>
        </Menu>
      </Box>
    ),
  });

  // Applies filter when updateFilterCriteria is called
  useEffect(() => {
    if (filterCriteria.length) {
      table.setColumnFilters(filterCriteria);
    }
  }, [filterCriteria, table]);

  return (
    <>
      <BlockedReasonModal
        setToast={setToast}
        showBlockedReasonModal={showBlockedReasonModal}
        setShowBlockedReasonModal={setShowBlockedReasonModal}
        originalBlockedTask={originalBlockedTask}
        setOriginalBlockedTask={setOriginalBlockedTask}
      />
      <MaterialReactTable table={table} />
    </>
  );
}
