import '../../css/WolfLoader.css';
// import qaWolfLogo from '../../utils/qa-wolf-logo.png';
// import pedroRaccoon from '../../utils/racoon-pedro-gif.gif';
import rickAstley from '../../utils/rick-astley.gif';

const WolfLoader = ({ customStyles = {}, customText = 'Loading...' }) => {
  return (
    <div className="wolf-loader-container" style={customStyles}>
      <img src={rickAstley} alt="Loading" className="wolf-loader" />
      <p>{customText}</p>
    </div>
  );
};

export default WolfLoader;
